import nxModule from 'nxModule';
import angular from 'angular';
import _ from 'lodash';
import {
  isCorporate,
  mapSignatoryToOwnership,
  multipleAccountOwnershipsEnabled,
  validateOwnerConfiguration
} from '../../common/casa-product-util';
import CASA_CLASS_CODES from '../../common/types/casa-class-codes.ts';
import {ATA_LABELS, ataReplenishStrategies} from "constants/account";

const templateUrl = require('./new-account-details.template.html');

nxModule.component('customerAccountCreateDetails', {
  templateUrl: templateUrl,
  controller: function ($timeout, $route, $location, $filter, $scope, http, casaTypesCache, customerCache,
                        productDefinitionService, depositAccountTypeService, confirmation, notification,
                        commandMetadataCache, command, branchService, dict, userService, centerService, authentication) {
    const that = this;

    that.customerId = $route.current.params['customerId'];
    const accountTypeId = $route.current.params['accountTypeId'];

    that.account = {};
    that.accountType = {};
    that.depositAccounts = [];
    /**
     * Multiple account ownership enabled when:
     * 1. Customer is INDIVIDUAL
     * 2. Casa PDIC casa types allows this feature.
     */
    that.multipleAccountOwnershipEnabled = false;
    that.hasValidOwnerConfiguration = false;

    that.casaClassCodes = CASA_CLASS_CODES;
    that.ataReplenishStrategies = ataReplenishStrategies;
    that.ataLabels = ATA_LABELS;
    that.ataEnabledReplenishStrategies = ataReplenishStrategies.filter(s => s.value !== 'NONE');

    that.selectAccountConfig = {
      placeholder: 'Select account',
      searchField: ['productNumber'],
      valueField: 'id',
      labelField: 'productNumber',
      maxItems: 1
    };
    that.centerConfig = {
      placeholder: 'Select center',
      searchField: ['name', 'id'],
      valueField: 'id',
      labelField: 'name',
      maxItems: 1
    };

    $scope.$watchCollection('$ctrl.account.ownership', () => {
      if (that.account) {
        that.hasValidOwnerConfiguration = validateOwnerConfiguration(that.profile, that.account, that.pdicCasaTypes);
      }
    });

    that.$onInit = async () => {
      const [officers, collectors] = await Promise.all([userService.getLoanOfficersAssignedToCurrentUserBranches(that.account.collectorId), userService.getCollectorsAssignedToCurrentUserBranches(that.account.collectorId)])

      const branchId = that.account.branchId || authentication.context.branchId;
      this.collectors = collectors.filter(user => user.branchIds.includes(branchId));
      this.officers = officers.filter(user => user.branchIds.includes(branchId));

      this.allCenters = await centerService.fetchCenters({});
    }

    const s1 = depositAccountTypeService.toObservable().subscribe(data => {
      that.accountType = _.find(data, {id: ~~accountTypeId});
      that.checking = ['CHECKING', 'COMBO'].includes(that.accountType.accountSubtype);
      that.account.ata = angular.copy(that.accountType.automaticTransferAgreement);
      that.account.updateAta = false;
      that.account.taxExempted = that.accountType.taxExempted;
    });

    // Fetch product definitions
    const s2 = productDefinitionService.toObservable()
      .subscribe(productTypes => {
        that.productTypes = _.filter(productTypes, {productGroup: 'ACCOUNT'});
        that.relationDefinitionsIds = _.map(that.productTypes, t => {
          return {label: t.productName, value: t.id}
        })
      });

    // Fetch ATA trigger & credit commands
    const s3 = commandMetadataCache.toObservable()
      .subscribe(commands => {
        that.triggerCommands = {};
        that.creditCommands = {};
        if (commands && commands.length > 0) {
          // Function to filter commands by tag and convert results to label/value form
          // label -> human readable command name, value -> command alias
          const extract = (tag) => {
            return _.map(_.filter(commands, c => c.alias && _.includes(c.tags, tag)), c => {
              return {label: $filter('startCase')(c.alias), value: c.alias}
            })
          };
          that.triggerCommands = extract('ATA_TRIGGER');
          that.creditCommands = extract('ATA_CREDIT');
        }
      });

    that.updateCenters = () => {
      if (!this.account.collectorId && !this.account.officerId) {
        this.availableCenters = [];
        this.account.centerId = null;
        return;
      }
      this.availableCenters = this.allCenters
        .filter(c => [c.loanOfficerId, c.loanCollectorId].includes(this.account.collectorId) || [c.loanOfficerId, c.loanCollectorId].includes(this.account.officerId));
      if (!_.find(this.availableCenters, {id: Number(this.account.centerId)})) {
        this.account.centerId = null;
      }
    }

    // This method will remove all invalid relations from [account.ataRelationsIds array]
    that.resetRelations = () => {
      // Exclude relations not matching allowed definitions
      const definitions = that.account.ata.relationDefinitionsIds;
      let relations = _.filter(that.allRelations, r => _.includes(definitions, r.definitionId));
      // Exclude accounts of related people if they are not allowed
      const allowExternal = that.account.ata.allowExternalRelations;
      if (!allowExternal) relations = _.filter(relations, {customerId: Number(that.customerId)});
      // Remove invalid relations from multiselect
      that.account.ataRelationsIds = _.intersection(that.account.ataRelationsIds, _.map(relations, 'id'));
      // Convert relations to label/value list (for multiselect)
      that.multiselectRelations = _.map(relations, r => {
        return {
          value: r.id,
          label: function () {
            const account = _.find(that.allRelations, {id: r.id});
            return account ? account.productNumber : 'Invalid product: ' + r.id;
          }()
        };
      });
    };

    that.findSignatoryNames = () => {
      let signatoryTypeId = _.find(dict.RELATIVE_TYPE, {code: 'SIGNATORY'}).id;
      that.signatoryRelatives = _.filter(that.profile.relatedCustomers, {typeIds: [signatoryTypeId]});
    };

    // Fetch all accounts belonging to customer (along with related customers)
    // All accounts are fetched to avoid refetching of accounts on each
    // change of [allowExternalRelations] change
    const s4 = customerCache.profile(that.customerId).toObservable()
      .combineLatest(branchService.toObservable(), (profile, branches) => {
        const branch = branches.find(b => Number(b.id) === Number(profile.branchId));
        const owner = {
          birthDate: profile.individualData ? profile.individualData.birthDate : null,
          branchId: profile.branchId,
          branchName: branch.name,
          customerId: that.customerId,
          customerNumber: profile.customerNumber,
          effectiveName: profile.effectiveName,
          ownershipType: 'OWNER'
        };
        that.profile = profile;

        dict.onLoadingComplete(() => {
          that.findSignatoryNames();
        });

        that.account.ownership = [owner];
        that.allRelations = [];
        // Create array containing id of current customer
        // and ids of all of related customers (if exists)
        let customerIds = [that.customerId];
        if (profile && profile.relatedCustomers && profile.relatedCustomers.length > 0) {
          customerIds = customerIds.concat(_.map(profile.relatedCustomers, 'relativeCustomerId'));
        }
        // Fetch active & dormant accounts for [customerIds]
        const criteria = {customerId: customerIds, productStatus: ['ACTIVE', 'INACTIVE']};
        http.post('/products/accounts/search', criteria, {nxLoaderSkip: true}).success(data => {
          if (data && data.result && data.result.length > 0) that.allRelations = data.result;
          that.resetRelations();
        });
        return profile.customerType;
      })
      .combineLatest(casaTypesCache.toObservable(), (customerType, casaTypes) => {
        that.casaTypes = casaTypes;
        that.pdicCasaTypes = _.filter(that.casaTypes, {'regulatorType': 'PDIC', customerType: customerType});

        if (that.profile?.corporateData?.businessTypeId !== dict.getId('BUSINESS_TYPE', 'SOLE_PROPRIETORSHIP')) {
          that.pdicCasaTypes = that.pdicCasaTypes.filter(t => t.code !== 'CORP_SSP');
        }
      })
      .subscribe();

    const s5 = customerCache.depositAccounts(that.customerId).toObservable()
      .subscribe(data => {
        that.depositAccounts = _.filter(data, a => a.status === 'ACTIVE');
      });

    that.checkIfCorporate = () => {
      if (that.profile && that.account && that.pdicCasaTypes) {
        that.multipleAccountOwnershipEnabled = multipleAccountOwnershipsEnabled(that.profile, that.account, that.pdicCasaTypes);
        if (!that.multipleAccountOwnershipEnabled) {
          that.account.ownership = that.account.ownership.filter(owner => {
            return owner.ownershipType === 'OWNER'
          });
        }
        return isCorporate(that.profile, that.account, that.pdicCasaTypes);
      }
      return false;
    };

    that.hasValidOwnerConfiguration = () => {
      return hasValidOwnerConfiguration(that.profile, that.account, that.pdicCasaTypes);
    };

    that.pdicCasaTypesChanged = () => {
      if (!that.checkIfCorporate()) {
        that.account.signatoryIds = [];
      }
      that.hasValidOwnerConfiguration = validateOwnerConfiguration(that.profile, that.account, that.pdicCasaTypes);
    };

    that.replenishUpdated = () => {
      if (!that.account.ata.replenishEnabled) {
        that.account.ata.replenishStrategy = 'NONE';
        that.account.ata.replenishThreshold = null;
      } else {
        that.account.ata.replenishStrategy = null;
        that.account.ata.replenishThreshold = null;
      }
    };

    //highlight required fields
    $timeout(() => that.form.$setSubmitted());

    that.accountLabel = (id) => {
      const account = _.find(that.allRelations, {id: id});
      return account ? account.productNumber : 'Invalid account';
    };

    that.open = () => {
      that.account.customerId = that.customerId;
      that.account.accountTypeId = that.accountType.id;

      const accountClone = angular.copy(that.account);

      accountClone.signatureCardFileId = _.map(that.signatureCardFiles, f => f.id)[0];
      accountClone.signatoryIds = accountClone.signatoryIds || [];
      let signatories = that.signatoryRelatives
        .filter(s => accountClone.signatoryIds.includes(s.id))
        .map(sig => mapSignatoryToOwnership(sig));
      accountClone.ownership = accountClone.ownership.concat(signatories);

      let message = `Do you want to open a "${that.accountType.productDefinition.productName}" account?`;
      http.post('/products/accounts/validate-prospect-account-type', accountClone).success((response) => {
        if (response.hasTypeConflict && that.accountType.typeConflictStrategy === 'SHOW_WARNING') {
          message = `This client already has at least one "${that.accountType.productDefinition.productName}" deposit
          account. Do you want to proceed?`;
        } else if (response.hasSubtypeConflict && that.accountType.subtypeConflictStrategy === 'SHOW_WARNING') {
          message = `This client already has at least one other ${that.accountType.accountSubtype.toLowerCase()} account.
          Do you want to proceed?`;
        }
        confirmation(message, () => {
          command.execute('CreateAccount', accountClone).success((response) => {
            customerCache.depositAccounts(that.customerId).refetch();
            that.redirect(response.output.id);
          });
        });
      })

    };

    that.redirect = (id) => {
      let path = `/customer/${that.customerId}/accounts`;
      if (id) {
        //redirect to newly created account
        path = path + '/' + id;
      } else {
        //redirect back
        path = path + '/create';
      }
      $location.path(path);
    };

    that.redirectBack = () => {
      confirmation('Do you want to cancel? Canceling will discard all changes.', () => that.redirect());
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
      s2.unsubscribe();
      s3.unsubscribe();
      s4.unsubscribe();
      s5.unsubscribe();
    };
  }
});
