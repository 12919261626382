import nxModule from 'nxModule';
import './customer-account-details-print.less';
import templateUrl from './customer-account-details-print.template.html';

class CustomerAccountDetailsPrint {

  constructor(customerCache, accountProductsCache, productDefinitionService, branchService,
              passbookService, interestBoardService, dict, loader) {
    this.customerCache = customerCache;
    this.accountProductsCache = accountProductsCache;
    this.productDefinitionService = productDefinitionService;
    this.branchService = branchService;
    this.interestBoardService= interestBoardService;
    this.dict = dict;
    this.loader = loader;
  }

  $onChanges() {
    this.dict.onLoadingComplete(() => this.loadProfile());
    this.loadAccountInfo();
  }

  async loadProfile() {
    this.profile = await this.customerCache.profile(this.customerId).toPromise();
    this.profile.relatedCustomers.forEach(relative => {
      relative.relation = relative.typeIds.map(typeId => this.dict.getDescription('RELATIVE_TYPE', typeId)).join(', ');
    });
    this.profile.relatedNonCustomers.forEach(relative => {
      relative.effectiveName = `${relative.lastName}, ${relative.firstName}`;
      relative.relation = this.dict.getDescription('RELATIVE_TYPE', relative.typeId);
    });
    this.profile.relatives = [...this.profile.relatedCustomers, ...this.profile.relatedNonCustomers];
  }

  async loadAccountInfo() {
    const [customerAccounts, accountTypes, productDefinitions, branches] = await Promise.all([
      this.customerCache.depositAccounts(this.customerId).toObservable().first().toPromise(),
      this.accountProductsCache.toPromise(),
      this.productDefinitionService.toPromise(),
      this.branchService.toPromise()
    ]);

    const account = customerAccounts.find(acc => acc.id == this.accountId);
    account.accountType = accountTypes.find(type => type.id == account.typeId);

    if (this.passbookSupported(account.accountType)) {
      try {
        const passbook = await this.passbookService.readByProductId(this.accountId);
        account.passbookNumber = passbook.number;
      } catch (err) {
        // catch 404 - passbook not found
        console.debug('Reading passbook', err);
      }
    }

    account.branchName = branches.find(branch => branch.id == account.branchId).name;
    account.productName = productDefinitions.find(product => product.id == account.definitionId).productName;
    account.subtype = account.accountType.accountSubtype || 'SAVINGS';
    account.interestRange = this.interestBoardService.formatInterestRateRangeForRateType(account.accountType.interestBoards);

    if (account.ataRelationsIds)
      account.relatedAccounts = customerAccounts.filter(acc => account.ataRelationsIds.includes(acc.id));

    this.account = account;
  }

  print() {
    window.print();
  }

  passbookSupported(accountType) {
    return accountType ? accountType.passbookConfig !== 'PASSBOOK_NOT_SUPPORTED' : false;
  }

}

nxModule.component('customerAccountDetailsPrint', {
  templateUrl,
  bindings: {
    customerId: '<',
    accountId: '<'
  },
  controller: CustomerAccountDetailsPrint
});