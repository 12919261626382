import _ from 'lodash';
import $ from 'jquery';

import nxModule from 'nxModule';

import './report-filter.style.less';
import moment from "moment/moment";

const templateUrl = require('./report-filters.template.html');
nxModule.component('reportFilters', {
  templateUrl: templateUrl,
  bindings: {
    /**
     config : {
        reportCode: 'LoanListingReport',
        buttons : {
          filter : {
            isVisible : true,
            isDisabled : false,
            action: () => {},
            text: 'Filter'
          }, download : {
            isVisible : true,
            isDisabled : false,
            action: () => {},
            text: 'Download xls'
          }, print : {
            isVisible : false,
            isDisabled : false,
            action: () => {},
            text: 'Print'
          }
        }
     }
     */
    config: '<',
    params: '='
  },
  controller: function (http, $element, systemDateService, authentication, $scope) {
    let that = this;
    const reportCode = that.config.reportCode;
    let lastParams = {};

    // reenable disabled view button when report filter changed
    $scope.$watch('$ctrl.params', () => {
      if(that.config.buttons.filter.isDisabled){
        that.config.buttons.filter.isDisabled = false;
      }
    }, true);


      http.get(`/reports/${reportCode}/filter-definitions`)
      .success(filterDefinition => {
        that.filterDefinition = filterDefinition;
        that.onFilterChange();
      });

    that.trackByFilter = (filter) => {
      const tracker = filter.code +'-'+ filter.context?.map(mappedFilter => mappedFilter.id);
      return tracker;
    }

    that.applyDefaults = () => {
      const params = that.params;
      const evaluateCondition = (condition) => {
        /*
          Condition has the following format:
          {
            value: 'xyz'
          }

          {
            code: 'xyz',
            item: 'id',
            condition: nestedCondition
          }

          {
            conditions: [nestedCondition]
          }

         */
        if (condition.value !== undefined) {
          return condition.value;
        }

        const nestedConditions = condition.conditions;
        if (nestedConditions) {
          const calculatedValues = nestedConditions.map(condition => {
            return evaluateCondition(condition);
          });

          return calculatedValues.find(value => value !== undefined);
        }

        if (condition.condition) {
          const {code, item} = condition;
          // if filter we check condition on is a multi-value one (is an array)
          // -> check if this array includes the value
          if (params[code] === item || (Array.isArray(params[code]) && params[code].includes(item))) {
            return evaluateCondition(condition.condition);
          }
        }
      };

      const applyDefaults = () => {
        let changed = false;
        that.filterDefinition.forEach(filter => {
          // if there is already a value in target filter, skip checking.
          // Null value is allowed because it means "all values"
          if (params[filter.code] !== undefined) {
            return;
          }

          // grab property holding default config
          const defaultCondition = filter['default'];

          // calculate recursively default value
          if(!defaultCondition) {
            return;
          }

          const defaultValue = evaluateCondition(defaultCondition);
          if (defaultValue !== undefined) {
            // Set parameter if default is null, meaning select "all values"
            // add support for null value
            const isAllValues = filter.type === 'MULTIPLE_CHOICE_LIST'
              && (defaultValue === null
                || (defaultValue.length === 1 && defaultValue[0] === null)
                || (defaultValue.length > 0 && defaultValue.length === filter.context.length)
              );

            // for multiselect filter, we send all option values from filter context if all options are selected
            params[filter.code] = isAllValues ? filter.context.map(filterContext => filterContext.id) : defaultValue;
            changed = true;

            const animationClass = 'autofilled-with-default';
            const $elementToAnimate = $element.find(`.filter-code-` + $.escapeSelector(`${filter.code}`));

            $elementToAnimate.one('transitionend webkitTransitionEnd oTransitionEnd', () => {
              $elementToAnimate.removeClass(animationClass);
            });

            requestAnimationFrame(() => {
              $elementToAnimate.addClass(animationClass);
            })
          }
        });

        return changed; // true if we changed default value of any parameter
      };

      // applying one default value can trigger other default to be valid
      // so let's try applying defaults at most a couple times if we still see
      // some changes. However, prevent infinite loop if there is some bug in applyDefault...
      let iteration = 1;
      while (applyDefaults() && iteration < 5) {
        iteration += 1;
      }

      if (iteration >= 5) {
        console.error('Defaults infinite loop.');
      }
    };

    that.onFilterChange = () => {
      that.applyDefaults();

      let iteration = 0;
      let originalParams = null;

      while (!_.isEqual(originalParams, that.params) && iteration < that.filterDefinition.length) {
        originalParams = _.cloneDeep(that.params);
        that.processFilterDefinition();
        iteration++;
      }

      if (iteration > that.filterDefinition.length) {
        throw new Error('Reached maximum loop for processFilterDefinition()');
      }
    };

    const DATE_FILTERS = ['DAY', 'MONTH', 'QUARTER', 'YEAR', 'DATE_RANGE'];

    const isContextFiltered = filter =>
      // if the filter's context hasn't changed the size we can assume it was not modified
      _.find(that.filterDefinition, {code: filter.code}).context.length !== filter.context.length;

    that.processFilterDefinition = () => {
      const filterDefinitionClone = _.cloneDeep(that.filterDefinition);

      for (let filter of filterDefinitionClone) {
        if (!filter.context) continue;
        filter.context = _.filter(filter.context, ctx => filterByCondition(ctx.condition));
      }

      // set selected param values which are not longer visible to null
      for (let filter of filterDefinitionClone) {
        // Date filters with default value null - will reset date to 1970.
        if (DATE_FILTERS.includes(filter.type) && that.params[filter.code] == null) {
          // Use current moment as default values in order to avoid potential null or invalid references
          that.params[filter.code] = {
            to: moment().format('YYYY-MM-DD'),
            from: moment().format('YYYY-MM-DD')
          };
        }
        if (filter.type === 'SINGLE_CHOICE_LIST' || filter.type === 'MULTIPLE_CHOICE_LIST') {
          let paramValue = that.params[filter.code];
          if (!paramValue) continue;

          // param that was selected is not visible anymore. Kill it!
          if (filter.type === 'MULTIPLE_CHOICE_LIST') {
            const isAllOption = paramValue.length === 1 && paramValue[0] === null;
            if (isAllOption) {
              that.params[filter.code] = filter.context.map(filterContext => filterContext.id)
            } else if (isContextFiltered(filter)) {
              that.params[filter.code] = _.intersection(_.map(filter.context, 'id'), paramValue);
            }
          } else if (!_.find(filter.context, {id: paramValue})) {
            if (filter['default'] && filter['default'].value) {
              // Fallback to default value if it is included in context
              let isDefaultAllowed = _.find(filter.context, {id: filter['default'].value});
              that.params[filter.code] = isDefaultAllowed ? filter['default'].value : null;
            }
          }
        }
      }

      if (!_.isEqual(that.effectiveFilterDefinition, filterDefinitionClone)) {
        // Only change the effectiveFilterDefinition object if there are actual changes in the filter definition values.
        // Otherwise we experience an infinite loop of ng-changes.
        that.effectiveFilterDefinition = filterDefinitionClone;
      }

      if (hasBranchFilterChanged()) {
        updateDateFilters();
      }

      lastParams = _.cloneDeep(that.params);
    };

    const hasBranchFilterChanged = () => {
      return hasParamChanged('branchId') || hasParamChanged('branchId[]');
    };

    const hasParamChanged = (paramName) =>
      (lastParams[paramName] === undefined && that.params[paramName]) || !_.isEqual(lastParams[paramName], that.params[paramName]);

    const updateDateFilters = () => {
      that.effectiveFilterDefinition
        .filter(filter => DATE_FILTERS.includes(filter.type))
        .forEach(filter => setFilterSelectedBranchSystemDate(filter.type, filter.code));
    };

    const setFilterSelectedBranchSystemDate = (filterType, filterCode) => {
      switch (filterType) {
        case 'DATE_RANGE':
          setSystemDateToDateRangeFilter(filterCode);
          break;
        case 'DAY':
          setSystemDateToDateDayFilter(filterCode);
          break;
        case 'MONTH':
          setSystemDateToDateMonthFilter(filterCode);
          break;
        case 'QUARTER':
          setSystemDateToDateQuarterFilter(filterCode);
          break;
        case 'YEAR':
          setSystemDateToDateYearFilter(filterCode);
          break;
      }
    };

    const setSystemDateToDateRangeFilter = async (dateFilterCode) => {
      const systemDate = await resolveSystemDate();
      if (!that.params[dateFilterCode]) that.params[dateFilterCode] = {};
      that.params[dateFilterCode].from = moment(systemDate).format('YYYY-MM-DD');
      that.params[dateFilterCode].to = moment(systemDate).format('YYYY-MM-DD');
    };

    const setSystemDateToDateDayFilter = async (dateFilterCode) => {
      const systemDate = await resolveSystemDate();
      that.params[dateFilterCode] = moment(systemDate).format('YYYY-MM-DD');
    };

    const setSystemDateToDateMonthFilter = async (dateFilterCode) => {
      const systemDate = await resolveSystemDate();
      // some features require month value before the current branch one
      that.month = new Date(moment(systemDate).subtract(1, 'MONTH').format('YYYY-MM'));
      that.formatMonthDate(dateFilterCode);
    };

    const setSystemDateToDateQuarterFilter = async (dateFilterCode) => {
      const systemDate = await resolveSystemDate();
      let refDate = moment(systemDate).toDate();
      let year = refDate.getFullYear();
      let month = refDate.getMonth() + 1;

      let previousQuarterEnd;
      if (month <= 3) {
        previousQuarterEnd = new Date(year - 1, 11, 31);
      } else if (month <= 6) {
        previousQuarterEnd = new Date(year, 2, 31);
      } else if (month <= 9) {
        previousQuarterEnd = new Date(year, 5, 30);
      } else {
        previousQuarterEnd = new Date(year, 8, 30);
      }

      let previousQuarterMoment = moment(previousQuarterEnd);
      that.month = new Date(previousQuarterMoment.format('YYYY-MM'));
      $('.quarter').attr('min', previousQuarterMoment.subtract(1, 'YEAR').format('YYYY-MM'))
      that.formatMonthDate(dateFilterCode);
    };

    const setSystemDateToDateYearFilter = async (dateFilterCode) => {
      const systemDate = await resolveSystemDate();
      // this is used to calculate last day in the given year, subtraction solves an issue of the result past system date
      that.params[dateFilterCode] = parseInt(moment(systemDate).subtract(1, 'YEAR').format('YYYY'));
    };

    const resolveSystemDate = async () => {
      const branchId = await getBranchId();

      if (Array.isArray(branchId)) {
        const systemDates = branchId.map(branchId => systemDateService.getSystemDateByBranchId(branchId));
        return getEarliestDate(await Promise.all(systemDates));
      }

      return await systemDateService.getSystemDateByBranchId(branchId);
    };

    const getBranchId = () => {
      const branchIdCandidate = that.params.branchId;
      if (branchIdCandidate == null ||
        (Array.isArray(branchIdCandidate) && branchIdCandidate[0] == null)) {
        return authentication.context.branchIds;
      }
      return branchIdCandidate;
    };

    const getEarliestDate = (array) =>
        array.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())[0];

    /**
     * Returns true if the conditions are met
     */
    function filterByCondition(conditions) {
      if (!conditions) return true;

      for (let paramKey of Object.keys(conditions)) {
        if (!conditionMatches(conditions, paramKey))
          return false;
      }
      return true;
    }

    function conditionMatches(conditions, paramKey) {
      // fetch the currently set param value
      // use null instead of undefined to be consistent with "all values"
      let value = that.params[paramKey];
      if (value == null || _.isEqual([null], value) ){
        let def = _.find(that.filterDefinition, {code: paramKey});
        value = def.context.map(c => c.id);
      }
      const conditionValue = conditions[paramKey];
      if (conditionValue instanceof Array) {

        //To compare array values from both params and conditions
        if  (value instanceof  Array) {
          if (_.intersection(conditionValue, value).length === 0) return false;
        } else {
          if (!_.includes(conditionValue, value)) return false;
        }
      } else {

        // check if parameter value is array
        if (value instanceof  Array) {
          if (_.includes(value, conditionValue)) return true;
        }

        // assume it's an object
        if (conditionValue !== value) return false;
      }
      return true;
    }


    that.formatMonthDate = (filterCode) => {
      that.params[filterCode] = moment(that.month).format('YYYY-MM-DD');
    };

    that.shouldRenderOnMissingContext = filter => {
      if (filter.type === 'MULTIPLE_CHOICE_LIST' && filter.dataType === 'LOAN_CATEGORY_TREE') {
        return filter.context && filter.context.length > 0;
      }
      return true;
    };

    that.getFilter = (code) => that.filterDefinition.find(fd => fd.code === code);

    // init years
    that.years = [];
    const year = moment().year();
    for (let i = 0; i < 5; i++) {
      that.years.push(year - i);
    }


    that.$onDestroy = () => {
      if (that.systemDateSub) {
        that.systemDateSub.unsubscribe();
      }
    }
  }
});
