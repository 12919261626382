import nxModule from 'nxModule';
import {BatchLoanPaymentService, LoanBatchFileOutput} from './batch-loan-payments.service';
import {UserDetails} from '../../service/users/user.cache';
import templateUrl from './batch-loan-payments-history.template.html'
import {NgTableParams} from 'ng-table';
import {HttpService} from "shared/utils/httpService";
import {ReportModal} from "components/report/modal/report-modal.service.types";

interface LoanBatchFileView extends LoanBatchFileOutput {
  uploadedBy: UserDetails;
}

class BatchLoanPaymentsHistory {
  tableConfig!: NgTableParams<LoanBatchFileOutput>;
  totalRowCount?: number;

  constructor(private readonly http: HttpService,
              private readonly batchLoanPaymentService: BatchLoanPaymentService,
              private readonly reportModal: ReportModal) { }

  async $onInit() {
    this.tableConfig = new NgTableParams({
      page: 1,
      count: 20
    }, {
      counts: [],
      total: 0,
      paginationMinBlocks: 3,
      paginationMaxBlocks: 8,
      getData: async (params: NgTableParams<LoanBatchFileView>) => {
        const request = {
          pageNo: params.page() - 1,
          pageSize: params.count()
        };
        const response = await this.batchLoanPaymentService.readLoanBatchFilesOutput(request);
        this.totalRowCount = response.totalCount;
        params.total(this.totalRowCount);
        return response.result;
      }
    });
  }

  showDetails(batchFileId: number): void {
    this.reportModal.display({
      params: {batchFileId},
      reportCode: 'LoanPaymentsReportNoFilter',
      hideXls: false
    });
  }

  showErrorDetails(batchFileId: number): void {
    this.reportModal.display({
      params: {batchFileId},
      reportCode: 'LoanBatchPaymentErrorsReport',
      hideXls: false
    });
  }
}

nxModule.component('batchLoanPaymentsHistory', {
  templateUrl,
  controller: BatchLoanPaymentsHistory,
});