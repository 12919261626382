import nxModule from 'nxModule';
import templateUrl from './related-customers.template.html';
import './related-customers.style.less'

class CustomerProfileRelatedCustomers {

  constructor($scope, $location, notification, dict, branchService, confirmation, propertyConfigService, http) {
    this.$scope = $scope;
    this.$location = $location;
    this.notification = notification;
    this.dict = dict;
    this.branchService = branchService;
    this.confirmation = confirmation;
    this.cfg = propertyConfigService;
    this.http = http;
    this.isInit = true;

    this.isRelativeSignatory = false;

    //wait for profile to be resolved
    this.$scope.$watch('$ctrl.profile.relatedCustomers', async () => {
      if (_.isArray(this.profile.relatedCustomers) && !_.isEmpty(this.profile.relatedCustomers)) {
        this.dict.onLoadingComplete(async () => {
            this.isAnyRelativeSignatory(this.profile.relatedCustomers, this.dict.RELATIVE_TYPE);
            const customerIds = this.profile.relatedCustomers.map(rc => rc.relativeCustomerId);
            const relatedCustomers = await this.http.get(`/customers/all?ids=${customerIds}`).toPromise();
            this.profile.relatedCustomers.forEach(r => {
              r.natureOfWork = this.resolveNatureOfWork(r);
              r.customerType = relatedCustomers.find(rc => rc.id === r.relativeCustomerId).customerType;
            });

            const relationshipTypes = this.dict.filterByAttribute('RELATIVE_TYPE', 'customerType', this.profile.customerType, 'ANY');
            const individualCompanyRoleCodes = ['PARTNER', 'DIRECTOR', 'OFFICER', 'STOCKHOLDER', 'RELATED_INTEREST'];
            const corporateCompanyRoleCodes = ['AFFILIATE', 'SUBSIDIARY', 'PARENT_COMPANY', 'STOCKHOLDER', 'RELATED_INTEREST'];
            this.corporateRelatedCustomerOptions =  [{id: -1, description: 'Company Role'}];
            this.individualRelatedCustomerOptions = relationshipTypes.filter(rt => !individualCompanyRoleCodes.includes(rt.code));
            this.individualCompanyRoleTypeOptions = relationshipTypes.filter(rt => individualCompanyRoleCodes.includes(rt.code));
            this.corporateCompanyRoleTypeOptions = relationshipTypes.filter(rt => corporateCompanyRoleCodes.includes(rt.code));

            if (this.isInit) {
              this.isInit = false;
              this.profile.relatedCustomers.forEach(rc => {
                if (this.profile.customerType === 'INDIVIDUAL') {
                  rc.individualCompanyRoleTypeId = this.dict.RELATIVE_TYPE.filter(type => rc.typeIds.includes(type.id)
                      && individualCompanyRoleCodes.includes(type.code))
                      .map(type => type.id)[0];
                  if (rc.individualCompanyRoleTypeId) {
                    // Set company role option selected since there are company role type ids selected
                    rc.typeIds.push(-1);
                  }
                } else {
                  rc.corporateCompanyRoleTypeId = this.dict.RELATIVE_TYPE.filter(type => rc.typeIds.includes(type.id)
                      && corporateCompanyRoleCodes.includes(type.code))
                      .map(type => type.id)[0];
                  if (rc.corporateCompanyRoleTypeId) {
                    // Set company role option selected since there are company role type ids selected
                    rc.typeIds.push(-1);
                  }
                }
              });
            }
          }
        );

        if (!this.startingRelatives) {
          this.startingRelatives = angular.copy(this.profile.relatedCustomers);
        }

        if (!this.branches) {
          this.branches = await this.branchService.toPromise();
        }
        this.profile.relatedCustomers.forEach(r => {
          r.branchName = (_(this.branches).find({id: r.branchId})|| {}).name
        });
      }
    }, true);
  }

  async addRelative(customer) {
    if (customer.id == this.profile.id) {
      this.notification.show('Warning', 'Customer cannot be relative to himself.');
      return;
    }
    if (_.find(this.profile.relatedCustomers, {relativeCustomerId: customer.id})) {
      this.notification.show('Warning', 'Customer has already been added.');
      return;
    }
    const r = await this.customerSearchToRelative(customer);
    this.profile.relatedCustomers.push(r);
  }

  async removeRelative(rel) {
    var array = this.profile.relatedCustomers;
    var index = array.indexOf(rel);
    if (this.hasSignatoryRelativeType(rel.typeIds, this.dict.RELATIVE_TYPE) && this.profile.customerType === 'CORPORATE') {
      if (await this.confirmation(`Deleting this relationship will remove this customer as signatory from ${this.profile.effectiveName} products.
        Do you want to proceed?`)) {
        array.splice(index, 1);
      }
    } else {
      array.splice(index, 1);
    }
  }

  showWarningOnSignatoryRemoval(relative) {
    this.isAnyRelativeSignatory(this.profile.relatedCustomers, this.dict.RELATIVE_TYPE);
    this.signatoryWarning(relative);
    if (relative.individualCompanyRoleTypeId) {
      relative.typeIds.push(relative.individualCompanyRoleTypeId);
    }
    if (relative.corporateCompanyRoleTypeId) {
      relative.typeIds.push(relative.corporateCompanyRoleTypeId);
    }
  }

  isAnyRelativeSignatory(relatives, relativeTypes) {
    const signatoryType = relativeTypes.find(rel => 'SIGNATORY' === rel.code);
    const relative = relatives.find(rel => rel.typeIds?.includes(signatoryType.id));
    this.isRelativeSignatory = !!relative;
  }

  signatoryWarning(relative) {
    let existingRelative = this.startingRelatives.find(r => r.id === relative.id);
    if (existingRelative
      && this.profile.customerType === 'CORPORATE'
      && this.hasSignatoryRelativeType(existingRelative.typeIds, this.dict.RELATIVE_TYPE)
      && !this.hasSignatoryRelativeType(relative.typeIds, this.dict.RELATIVE_TYPE)) {
      this.notification.show('Warning', 'Changing type to non-Signatory. Relative will be removed from products.')
      relative.withdrawalLimit = null;
    }
  }

  hasSignatoryRelativeType(ids, relativeTypes) {
    const signatory = relativeTypes.find(value => value.code === 'SIGNATORY');
    return ids.includes(signatory?.id);
  }

  openCustomer(customerId) {
    let redirectionUrl = `/customer/${customerId}/accounts`;
    this.$location.path(redirectionUrl);
  }

  async customerSearchToRelative(customer) {
    const mobilePhones = customer.phones.filter(phone => phone.type === 'MOBILE');
    const primaryMobilePhone = mobilePhones.find(p => p.primary) || mobilePhones[0];
    const phoneNumber = primaryMobilePhone ? primaryMobilePhone.number : null;

    const primaryIncomeSource = customer.incomeSources.find(inc => inc.primary);
    const natureOfWorkId = primaryIncomeSource?.natureOfWorkId;

    const branches = await this.branchService.toPromise();
    return {
      effectiveName: customer.effectiveName,
      extension: customer.individualData?.extension,
      extensionId: customer.individualData?.extensionId,
      birthDate : customer.individualData?.birthDate,
      birthPlace: customer.individualData?.birthPlace,
      mobileNumber: phoneNumber,
      natureOfWorkId: natureOfWorkId,
      customerNumber : customer.customerNumber,
      branchId : customer.branchId,
      branchName : branches.find(b => b.id === customer.branchId).name,
      relativeCustomerId: customer.id,
      withdrawalLimit: customer.withdrawalLimit,
      typeIds : [],
      beneficiary : false,
      dependent : false
    };
  }

  resolveNatureOfWork(customer) {
    return this.dict.getDescription('NATURE_OF_WORK_BUSINESS', customer.natureOfWorkId);
  }
}

nxModule.component('customerProfileRelatedCustomers', {
  templateUrl: templateUrl,
  bindings: {
    'profile': '=',
    'editMode': '<'
  },
  controller: CustomerProfileRelatedCustomers
});
