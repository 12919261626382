import React from 'react';
import styles from "../TableStyle.scss";
import { translateFieldKey, formatDate } from "../translations";

interface CloseReactivateCustomerViewProps {
  changes: Record<string, any>;
}

export const CloseReactivateCustomerView: React.FC<CloseReactivateCustomerViewProps> = ({ 
  changes 
}: any) => {
  const formatValue = (value: any): React.ReactNode => {
    if (value === null || value === undefined) {
      return <strong>None</strong>;
    }
    if (Array.isArray(value)) return JSON.stringify(value);
    if (typeof value === "object") return JSON.stringify(value);
    return formatDate(String(value));
  };

  return (
    <div>
      <table className={styles.updateTable}>
        <thead>
          <tr>
            <th className="text-left p-2">Field</th>
            <th className="text-left p-2">Previous Value</th>
            <th className="text-left p-2">Updated Value</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(changes).map(([key, value]: [string, any]) => (
            <tr key={key}>
              <td className="p-2 border">{translateFieldKey(key)}</td>
              <td className="p-2 border">{formatValue(value.previousValue)}</td>
              <td className="p-2 border">{formatValue(value.newValue)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};