import nxModule from "nxModule";
import ReportService, {FileReportFilter} from "../../../../react/report/ReportService"
import templateUrl from "./custom-report-detail.template.html";
import Popup from "shared/common/popup";

class CustomReportDetail {
  private readonly reportService = new ReportService()
  private readonly popup: Popup;
  private readonly reportName!: string;
  private filters: FileReportFilter[] = [];
  private parameters: Record<string, unknown> | undefined;

  constructor($routeParams: ng.route.IRouteParamsService, popup: Popup) {
    this.popup = popup;
    this.reportName = $routeParams.reportCode;
  }

  async $onInit(): Promise<void> {
    this.filters = await this.reportService.readFileReportFilters(this.reportName);
    this.parameters = {};

    this.filters?.filter(f => f.type === 'HIDDEN_BOOLEAN').forEach(f => {
      this.parameters![f.name] =  f.value?.toLowerCase() === 'true';
    })
  }

  async generateReport(): Promise<void> {
    this.popup({ text: "Request sent to reporting tool." });
    await this.reportService.generateFileReport({
      reportName: this.reportName,
      parameters : this.parameters
    });
  }
}

nxModule.component('customReportDetail', {
  templateUrl,
  controller: CustomReportDetail
});
