import { translateFieldKey } from "../translations";
import styles from "../TableStyle.scss";

interface FieldValue {
  previousValue: any;
  newValue: any;
}

interface Changes {
  [key: string]: FieldValue | any;
}

interface SectionField {
  key: string;
  label: string;
  value: any;
  order?: number;
}

interface Section {
  title: string;
  fields?: SectionField[];
  subsections?: Section[];
}

export const CreateCustomerView = ({ changes }: { changes: Changes }) => {
  const formatValue = (value: any): any => {
    if (value === null || value === undefined) return "-";
    if (typeof value === "object" && "newValue" in value) {
      return formatValue(value.newValue);
    }
    return String(value);
  };
  const formatMeasurement = (major?: any, minor?: number, type?: string): string => {
    if (!major?.newValue) return "-";

    const delimiter = type === "height" ? "'" : ".";
    const unit = type === "height" ? "ft" : "lbs";

    const formattedMinor = minor !== undefined && minor !== null ? `${delimiter}${minor}` : "";
    return `${major}${formattedMinor} ${unit}`;
  };

  const formatHeight = (major?: number, minor?: number): string => {
    return formatMeasurement(major, minor, "height");
  };

  const formatWeight = (major?: number, minor?: number): string => {
    return formatMeasurement(major, minor, "weight");
  };

  const formatIncome = (income?: number): string => {
    if (income === undefined || income === null) return "-";

    const formattedCash = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(formatValue(income));

    return isNaN(Number(formattedCash.replace(/,/g, ""))) ? "-" : `${formattedCash} PHP`;
  };


  // Sections are hard-coded defined because this determines the order of which they appear in the UI
  // We try to group together fields that are related to each other, and separate ones that are distinct
  const getIndividualDataSections = (changes: Changes): Section[] => {
    return [
      {
        title: "Personal Data",
        fields: [
          { key: "title", label: "Title", value: changes["individualData - titleId"] },
          { key: "firstName", label: "First name", value: changes["individualData - firstName"] },
          { key: "middleName", label: "Middle name", value: changes["individualData - middleName"] },
          { key: "lastName", label: "Last name", value: changes["individualData - lastName"] },
          { key: "extension", label: "Extension", value: changes["individualData - extension"] },
          { key: "nickname", label: "Nickname", value: changes["individualData - nickname"] },
          { key: "previousLastName", label: "Previous Last Name", value: changes["individualData - previousLastName"] },
        ],
      },
      {
        title: "Demographics",
        fields: [
          { key: "gender", label: "Gender", value: changes["individualData - genderId"] },
          {
            key: "height",
            label: "Height",
            value: formatHeight(
              changes["individualData - heightMajor"]?.newValue || changes["individualData - heightMajor"],
              changes["individualData - heightMinor"]?.newValue || changes["individualData - heightMinor"]
            ),
          },
          {
            key: "weight",
            label: "Weight",
            value: formatWeight(
              changes["individualData - weightMajor"]?.newValue || changes["individualData - weightMajor"],
              changes["individualData - weightMinor"]?.newValue || changes["individualData - weightMinor"]
            ),
          },
          { key: "civilStatus", label: "Civil Status", value: changes["individualData - civilStatusId"] },
          { key: "birthDate", label: "Birth Date", value: changes["individualData - birthDate"] },
          { key: "age", label: "Age", value: changes["individualData - age"] },
          { key: "birthPlace", label: "Birth Place", value: changes["individualData - birthPlace"] },
          { key: "birthCountry", label: "Birth Country", value: changes["individualData - birthCountryId"] },
          { key: "citizenship", label: "Citizenship", value: changes["individualData - citizenshipId"] },
          { key: "residence", label: "Residence", value: changes["individualData - residenceId"] },
        ],
      },
      {
        title: "Related People",
        fields: [
          {
            key: "mothersFirstName",
            label: "Mother's first name",
            value: changes["individualData - mothersFirstName"],
          },
          {
            key: "mothersMiddleName",
            label: "Mother's middle name",
            value: changes["individualData - mothersMiddleName"],
          },
          {
            key: "mothersMaidenName",
            label: "Mother's maiden name",
            value: changes["individualData - mothersLastName"],
          },
        ],
      },
      {
        title: "Contact Data",
        fields: [
          { key: "email", label: "Email address", value: changes["individualData - email"] },
          { key: "landlineNumber", label: "Landline number", value: changes["phones - landlineNumber"] },
          { key: "mobileNumber", label: "Mobile number", value: changes["phones - mobileNumber"] },
          { key: "mobilePhoneType", label: "Type of mobile phone", value: changes["phones - type"] },
          { key: "mobilePlan", label: "Mobile plan", value: changes["phones - planId"] },
          { key: "mobileMonthlyExpense", label: "Mobile monthly expense", value: changes["phones - monthlyExpense"] },
          { key: "workNumber", label: "Work number", value: changes["phones - workNumber"] },
        ],
      },
      {
        title: "Phones",
        fields: [],
        subsections: [createMultipleInstanceSection("Phones", "phones", changes, "phones")],
      },
      {
        title: "Compliance",
        fields: [
          { key: "riskLevel", label: "Risk level", value: changes["riskLevel"] },
          {
            key: "expectedMonthlyTransactionId",
            label: "Average monthly deposit amount",
            value: changes["individualData - expectedMonthlyTransactionId"],
          },
          { key: "dosri", label: "DOSRI", value: changes["dosri"] },
          {
            key: "relatedPartyTransaction",
            label: "Related Party Transaction",
            value: changes["individualData - relatedPartyTransaction"],
          },
          { key: "pep", label: "PEP", value: changes["individualData - pep"] },
          { key: "vip", label: "VIP", value: changes["individualData - vip"] },
          { key: "signatureUpload", label: "Signature upload", value: changes["individualData - signatureFileId"] },
          { key: "pictureUpload", label: "Picture upload", value: changes["pictureFileId"] },
          { key: "sanctionedEntity", label: "Sanctioned Entity", value: changes["sanctionedEntity"] },
        ],
      },
      {
        title: "Other",
        fields: [
          { key: "maxCoMakerLimit", label: "Max co-maker limit", value: changes["maxCoMakerLimit"] },
          { key: "comments", label: "Comments", value: changes["comments"] },
          { key: "isPwd", label: "Is PWD?", value: changes["isPwd"] },
          { key: "branchId", label: "Branch ID", value: changes["branchId"] },
        ],
      },
      {
        title: "Referrals",
        fields: [{ key: "referredBy", label: "Referred by", value: changes["referredBy"] }],
      },
      {
        title: "Cooperative Data",
        fields: [{ key: "membershipType", label: "Membership Type", value: changes["membershipTypeId"] }],
      },
    ];
  };

  const getExtendedIndividualSections = (changes: Changes): Section[] => {
    return [
      createMultipleInstanceSection("Documents", "idDocuments", changes, "idDocuments"),
      createMultipleInstanceSection("Address", "addresses", changes, "addresses"),
      {
        title: "Income",
        fields: [
          {
            key: "totalAnnualIncome",
            label: "Total Monthly Income",
            value: changes["individualData - totalAnnualIncome"]?.newValue
              ? formatIncome(changes["individualData - totalAnnualIncome"].newValue / 12)
              : "-",
          },
          {
            key: "totalAnnualIncome",
            label: "Total Annual Income",
            value: formatIncome(changes["individualData - totalAnnualIncome"]),
          },
       ],
        subsections: [createMultipleInstanceSection("Income Sources", "incomeSources", changes, "incomeSources")],
      },
      {
        title: "Related Customers",
        fields: [],
        subsections: [
          createMultipleInstanceSection("Related Customers", "relatedCustomers", changes),
          createMultipleInstanceSection("Non-related Customers", "relatedNonCustomers", changes),
        ],
      },
      {
        title: "Advanced",
        fields: [
          ...Object.entries(changes.customFieldValues || {}).map(([id, data]: [string, any]) => ({
            key: id,
            label: `Custom Field — ${data.field}`,
            value: data.newValue,
          })),
          ...Object.entries(changes.categoryIds || {}).map(([id, data]: [string, any]) => ({
            key: id,
            label: `Category — ${data.field}`,
            value: data.newValue,
          })),
        ].filter((field) => field.value !== null && field.value !== undefined),
      },
    ];
  };

  const getCorporateDataSections = (changes: Changes): Section[] => {
    return [
      {
        title: "Company Data",
        fields: [
          { key: "businessName", label: "Business name", value: changes["corporateData - businessName"] },
          { key: "tradeName", label: "Trade name", value: changes["corporateData - tradeName"] },
          { key: "businessType", label: "Business Type", value: changes["corporateData - businessTypeId"] },
          { key: "sourceOfFunds", label: "Source of Funds", value: changes["corporateData - sourceOfFunds"] },
          { key: "firmSize", label: "Firm Size", value: changes["corporateData - firmSizeId"] },
          {
            key: "placeOfIncorporation",
            label: "Place of Incorporation",
            value: changes["corporateData - placeOfIncorporation"],
          },
          { key: "registrationDate", label: "Registration Date", value: changes["corporateData - registrationDate"] },
          {
            key: "registrationNumber",
            label: "Registration Number",
            value: changes["corporateData - registrationNumber"],
          },
          {
            key: "registrationCountry",
            label: "Registration Country",
            value: changes["corporateData - registrationCountryId"],
          },
          { key: "email", label: "Email", value: changes["corporateData - email"] },
          { key: "conglomerateId", label: "Conglomerate", value: changes["corporateData - conglomerateId"] },
        ],
      },
      {
        title: "Contact Data",
        fields: [],
        subsections: [createMultipleInstanceSection("Contact", "phones", changes, "phones")],
      },
      {
        title: "Compliance",
        fields: [
          { key: "riskLevel", label: "Risk Level", value: changes["corporateData - riskLevelId"] },
          {
            key: "expectedMonthlyTransactionId",
            label: "Average monthly deposit amount",
            value: changes["corporateData - expectedMonthlyTransactionId"],
          },
          { key: "dosri", label: "DOSRI", value: changes["dosri"] },
          {
            key: "relatedPartyTransaction",
            label: "Related Party Transaction",
            value: changes["corporateData - relatedPartyTransaction"],
          },
          { key: "signatureFileId", label: "Signature upload", value: changes["corporateData - signatureFileId"] },
          { key: "pictureUpload", label: "Picture upload", value: changes["pictureFileId"] },
          { key: "sanctionedEntity", label: "Sanctioned Entity", value: changes["corporateData - sanctionedEntity"] },
          {
            key: "relatedPartyTransaction",
            label: "Related Party Transaction",
            value: changes["corporateData - relatedPartyTransaction"],
          },
          {
            key: "relatedPartyTransactionDescription",
            label: "Related Party Transaction Description",
            value: changes["corporateData - relatedPartyTransactionDescription"],
          },
        ],
      },
      {
        title: "Cooperative Data",
        fields: [{ key: "membershipType", label: "Membership Type", value: changes["membershipTypeId"] }],
      },
      {
        title: "Related Customers",
        fields: [],
        subsections: [
          createMultipleInstanceSection("Related Customers", "relatedCustomers", changes),
          createMultipleInstanceSection("Non-related Customers", "relatedNonCustomers", changes),
        ],
      },
      {
        title: "Documents",
        fields: [],
        subsections: [createMultipleInstanceSection("Documents", "idDocuments", changes, "idDocuments")],
      },
      {
        title: "Income",
        fields: [
          {
            key: "totalAnnualIncome",
            label: "Total Monthly Income",
            value: changes["corporateData - totalAnnualIncome"]?.newValue
              ? formatIncome(changes["corporateData - totalAnnualIncome"].newValue / 12)
              : "-",
          },
          {
            key: "totalAnnualIncome",
            label: "Total Annual Income",
            value: formatIncome(changes["corporateData - totalAnnualIncome"]),
          },
      ],
        subsections: [createMultipleInstanceSection("Income Sources", "incomeSources", changes, "incomeSources")],
      },
      {
        title: "Other",
        fields: [
          { key: "branchId", label: "Branch ID", value: changes["branchId"] },
          { key: "comments", label: "Comments", value: changes["comments"] },
        ],
      },
      {
        title: "Advanced",
        fields: [
          ...Object.entries(changes.customFieldValues || {}).map(([id, data]: [string, any]) => ({
            key: id,
            label: `Custom Field — ${data.field}`,
            value: data.newValue,
          })),
          ...Object.entries(changes.categoryIds || {}).map(([id, data]: [string, any]) => ({
            key: id,
            label: `Category — ${data.field}`,
            value: data.newValue,
          })),
        ].filter((field) => field.value !== null && field.value !== undefined),
      },
    ];
  };

  interface FieldConfig {
    key: string;
    label: string;
    order?: number;
  }

  const sectionConfigs: { [key: string]: FieldConfig[] } = {
    idDocuments: [
      { key: "documentTypeId", label: "Type" },
      { key: "documentStatusId", label: "Status" },
      { key: "documentNumber", label: "Number" },
      { key: "documentName", label: "Name" },
      { key: "issuingAuthority", label: "Issuing authority" },
      { key: "countryId", label: "Issuing country" },
      { key: "issuanceDate", label: "Issuance date" },
      { key: "dateSubmitted", label: "Submission date" },
      { key: "photoFileIds", label: "Document photo" },
      { key: "customerId", label: "Customer ID" },
      { key: "attributes", label: "Attributes" },
      { key: "expirationDate", label: "Expiration date" },
      { key: "remarks", label: "Remarks" },
    ],
    addresses: [
      { key: "type", label: "Type" },
      { key: "yearsOfStay", label: "Years of stay" },
      { key: "ownership", label: "Ownership" },
      { key: "countryId", label: "Country" },
      { key: "province", label: "Province" },
      { key: "city", label: "City" },
      { key: "barangay", label: "Barangay" },
      { key: "psgcCode", label: "PSGC Code" },
      { key: "street", label: "Street" },
      { key: "postalCode", label: "Postal code" },
      { key: "primary", label: "Primary" },
      { key: "rentAmount", label: "Rent amount" },
      { key: "rentContract", label: "Rent contract" },
      { key: "geoLocationId", label: "Geo location ID" },
      { key: "customerApplicationId", label: "Customer application ID" },
    ],
    incomeSources: [
      { key: "type", label: "Income source" },
      { key: "primary", label: "Primary" },
      { key: "annualIncome", label: "Annual income amount" },
      { key: "natureOfWorkId", label: "Nature of work/business" },
      { key: "psicId", label: "PSIC/Industry equivalent" },
      { key: "workplaceCharacteristicsId", label: "Workplace characteristics" },

      { key: "employerName", label: "Employer name" },
      { key: "employerBranch", label: "Employer branch" },
      { key: "employerType", label: "Employer type" },
      { key: "employmentStartDate", label: "Employment start date" },
      { key: "landlineNumber", label: "Landline number" },
      { key: "mobileNumber", label: "Mobile number" },
      { key: "countryId", label: "Country" },
      { key: "province", label: "Province" },
      { key: "city", label: "City" },
      { key: "barangay", label: "Barangay" },
      { key: "postalCode", label: "Postal code" },

      { key: "companyName", label: "Company name" },
      { key: "description", label: "Description" },
      { key: "businessTin", label: "Business TIN" },
      { key: "noOfPaidEmployees", label: "No. of paid employees" },
      { key: "businessStartDate", label: "Business start date" },
      { key: "businessStructure", label: "Business structure" },
      { key: "companyTotalAssets", label: "Business total assets" },
      { key: "principalPlaceOfOperations", label: "Principal Place of Operations" },
      { key: "businessAddressOwnershipType", label: "Business Address Ownership" },
      { key: "noOfBranches", label: "Number of Branches" },
      { key: "avgDailySales", label: "Avg. Daily Sales" },
      { key: "avgMonthlySales", label: "Avg. Monthly Sales" },
      { key: "avgMonthlyExpenses", label: "Avg. Monthly Expenses" },

      { key: "companyAddress - id", label: "Business ID" },
      { key: "companyAddress - city", label: "Business City" },
      { key: "companyAddress - type", label: "Business Type" },
      { key: "companyAddress - street", label: "Business Street" },
      { key: "companyAddress - primary", label: "Business Primary" },
      { key: "companyAddress - barangay", label: "Business Barangay" },
      { key: "companyAddress - province", label: "Business Province" },
      { key: "companyAddress - countryId", label: "Business Country" },
      { key: "companyAddress - ownership", label: "Business Ownership" },
      { key: "companyAddress - customerId", label: "Business Customer ID" },
      { key: "companyAddress - postalCode", label: "Business Postal Code" },
      { key: "companyAddress - rentAmount", label: "Business Rent Amount" },
      { key: "companyAddress - yearsOfStay", label: "Business Years of Stay" },
      { key: "companyAddress - rentContract", label: "Business Rent Contract" },
      { key: "companyAddress - geoLocationId", label: "Business Geo Location ID" },
      { key: "companyAddress - customerApplicationId", label: "Business Customer Application ID" },

      { key: "sssNumber", label: "SSS Number" },
      { key: "sssPensionTargetAccountId", label: "SSS Pension Target Account" },
      { key: "otherExistingBankAccounts", label: "Other Existing Bank Accounts" },
      { key: "otherExistingBankAccountsBankId", label: "Other Bank ID" },

      { key: "depEdRegionCode", label: "DepEd Region Code" },
      { key: "depEdDivisionCode", label: "DepEd Division Code" },
      { key: "depEdStationCode", label: "DepEd Station Code" },
      { key: "depEdEmployeeNumber", label: "DepEd Employee Number" },
    ],
    phones: [
      { key: "number", label: "Number" },
      { key: "type", label: "Type" },
      { key: "planId", label: "Plan" },
      { key: "primary", label: "Primary" },
      { key: "monthlyExpenseId", label: "Monthly Expense" },
    ],
  };

  const groupFieldsByNumber = (entries: [string, any][], prefix: string, fieldConfig?: FieldConfig[]): any => {
    const groups: { [key: string]: SectionField[] } = {};

    entries
      .filter(([key]) => key.startsWith(prefix))
      .forEach(([key, value]) => {
        const [prefixPart, number] = key.split(/\s+/);
        if (!number) return;

        const fieldName = key.substring(key.indexOf("-") + 1).trim();

        if (fieldConfig) {
          const configItem = fieldConfig.find((config) => fieldName === config.key);
          if (!configItem) return;

          if (!groups[number]) {
            groups[number] = [];
          }

          groups[number].push({
            key,
            label: configItem.label,
            value: value,
            order: fieldConfig.findIndex((config) => config.key === fieldName),
          });
        } else {
          if (!groups[number]) {
            groups[number] = [];
          }

          groups[number].push({
            key,
            label: fieldName,
            value: value,
          });
        }
      });

    if (fieldConfig) {
      Object.values(groups).forEach((fields) => {
        fields.sort((a, b) => (a.order || 0) - (b.order || 0));
      });
    }

    return groups;
  };

  const createMultipleInstanceSection = (title: string, prefix: string, changes: Changes, configKey?: string): any => {
    const fieldConfig = configKey ? sectionConfigs[configKey] : undefined;
    const groups = groupFieldsByNumber(Object.entries(changes), prefix, fieldConfig);

    return {
      title,
      fields: [],
      subsections: Object.entries(groups).map(([number, fields]) => ({
        title: `${title} ${number}`,
        fields: fields,
      })),
    };
  };

  const renderSubsection = (subsection: Section) => {
    if (!subsection.fields?.length && !subsection.subsections?.length) return null;

    return (
      <div key={subsection.title}>
        {subsection.fields && subsection.fields.length > 0 && (
          <>
            <h4 className={styles.subsectionTitle}>{subsection.title}</h4>
            <table className={styles.sectionTable}>
              <tbody>
                {subsection.fields.map((field, idx) => (
                  <tr key={`${field.key}-${idx}`}>
                    <td className={styles.cellField}>{translateFieldKey(field.label)}</td>
                    <td className={styles.cellValue}>
                      {["annualincome", "amount", "sales", "expenses", "assets"].some(keyword =>
                        field.key.toLowerCase().includes(keyword)
                      )
                        ? formatIncome(field.value)
                        : formatValue(field.value)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
        {/* Recursively render subsections if they exist */}
        {subsection.subsections?.map(renderSubsection)}
      </div>
    );
  };

  const renderSection = (section: Section) => {
    if (!section.fields?.length && !section.subsections?.length) return null;

    return (
      <div key={section.title}>
        <div className={styles.sectionHeader}>{section.title}</div>
        {section.fields && section.fields.length > 0 && (
          <table className={styles.sectionTable}>
            <tbody>
              {section.fields.map((field, idx) => (
                <tr key={`${field.key}-${idx}`}>
                  <td className={styles.cellField}>{translateFieldKey(field.label)}</td>
                  <td className={styles.cellValue}>{formatValue(field.value)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {section.subsections?.map((subsection) => renderSubsection(subsection))}
      </div>
    );
  };

  const isIndividual = changes.customerType?.newValue === "INDIVIDUAL";

  const sections = isIndividual
    ? [...getIndividualDataSections(changes), ...getExtendedIndividualSections(changes)]
    : getCorporateDataSections(changes);

  return (
    <div>
      <h2>{isIndividual ? "Individual Data" : "Corporate Data"}</h2>

      {sections.map((section) => renderSection(section))}
    </div>
  );
};
