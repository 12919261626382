import React, { ReactElement, useEffect, useState } from "react";
import { ExpandableRowsTable } from "./ExpandableRowsTable";
import useAxios from "axios-hooks";
import styles from "./TableStyle.scss";
import processChanges from "./data";
import { translations } from "./translations";
import { NxLoader } from "@nextbank/ui-components";

export interface TableColumn<T> {
  header: string;
  accessorKey?: keyof T;
  cell?: (props: { row: any }) => React.ReactNode;
  className?: string;
}

interface FieldChange {
  previousValue: [];
  newValue: [];
}

interface Changes {
  [key: string]: FieldChange | any;
}

interface ProfileHistoryEntry {
  id: number;
  entryGroup: "UPDATE_CUSTOMER" | "MERGE_CUSTOMER" | "CREATE_CUSTOMER" | "REACTIVATE_CUSTOMER" | "CLOSE_CUSTOMER";
  customerId: number;
  commandId: number;
  changes: Changes;
  executionTime: string;
  approvalTime: string;
  branchName: string;
  initiatorEffectiveName: string;
  approverEffectiveNames: string[] | null;
  commandStatus?: string;
}

const getEntryGroupDisplay = (entryGroup: string): string => {
  const mappings: Record<string, string> = {
    'CREATE_CUSTOMER': translations.createCustomer,
    'MERGE_CUSTOMER': translations.mergeCustomer,
    'UPDATE_CUSTOMER': translations.updateCustomer,
    'REACTIVATE_CUSTOMER': translations.reactivateCustomer,
    'CLOSE_CUSTOMER': translations.closeCustomer
  };
  
  return mappings[entryGroup] || entryGroup;
};

const PAGE_SIZE = 10;

const useProfileHistory = (customerId: string) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [{ data, loading, error }, refetch] = useAxios({
    url: `/customers/${customerId}/history`,
    params: {
      pageNo: currentPage,
      pageSize: PAGE_SIZE
    }
  });

  useEffect(() => {
    if (data?.totalCount) {
      setTotalPages(Math.ceil(data.totalCount / PAGE_SIZE));
    }
  }, [data?.totalCount]);

  return {
    customerData: data,
    loading,
    error,
    currentPage,
    totalPages,
    setCurrentPage,
    refetch
  };
};

export const ProfileHistoryTable: React.FC = (props: any) => {
  const getCustomerIdFromUrl = (): string | undefined => {
    const fullPath = window.location.hash || window.location.pathname;
    const matches = fullPath.match(/(?:#!)?\/customer\/(\d+)\/profile/);

    return matches?.[1];
  };

  const customerId = props?.prop?.id ?? getCustomerIdFromUrl();

  const {
    customerData,
    loading,
    currentPage,
    totalPages,
    setCurrentPage
  } = useProfileHistory(customerId);

  const columns: TableColumn<ProfileHistoryEntry>[] = [
    {
      header: translations.action,
      accessorKey: "entryGroup",
      cell: function renderCell({ row }: any): ReactElement {
        return <span>{getEntryGroupDisplay(row.original.entryGroup)}</span>;
      },
    },
    {
      header: translations.executionDateTime,
      accessorKey: "executionTime",
      cell: function renderCell({ row }: any): ReactElement {
        return <span>{new Date(row.original.executionTime).toLocaleString()}</span>;
      },
    },
    {
      header: translations.initiatingUser,
      accessorKey: "initiatorEffectiveName",
    },
    {
      header: translations.approver,
      accessorKey: "approverEffectiveNames",
      cell: function renderCell({ row }: any): ReactElement {
        return (<span>
          {Array.isArray(row.original.approverEffectiveNames) && row.original.approverEffectiveNames.length > 0
            ? row.original.approverEffectiveNames.map((name: string, index: number) => <div key={index}>{name}</div>)
            : translations.notApplicable}
        </span>)
      },
    },
    {
      header: translations.approvalDateTime,
      accessorKey: "approvalTime",
      className: styles["approval"],
      cell: function renderCell({ row }: any): ReactElement {
        return <span>{new Date(row.original.approvalTime).toLocaleString()}</span>;
      },
    },
    {
      header: translations.commandStatus,
      accessorKey: "commandStatus",
      cell: function renderCell({ row }: any): ReactElement {
        return (
          <span className={`status-${(row.original.commandStatus || "").toLowerCase()}`}>
            {row.original.commandStatus || translations.notApplicable}
          </span>
        );
      },
    },
  ];

  return (
    <>
      {customerData?.result && !loading ? (
        <ExpandableRowsTable data={processChanges(customerData).result}       columns={columns}
        currentPage={currentPage}
        totalPages={totalPages}
        totalResults={customerData.totalCount}
        onPageChange={setCurrentPage}
        pageSize={PAGE_SIZE} />
      ) : (
        <NxLoader/>
      )}
    </>
  );
};

export default ProfileHistoryTable;
