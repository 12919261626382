// polyfills
// vendor imports
import 'angular';

import BigNumber from 'bignumber.js';
// administration/administration-branches
import 'components/administration/administration-branches/administration-branches.component';
import 'components/administration/administration-branches/branch-details/branch-details.component';
// administration/areas
import 'components/administration/areas';
// administration/casa/interest-board
import 'components/administration/casa/interest-board/interest-board.component';
import 'components/administration/casa/interest-board/interest-board.service';

// administration/common
import 'components/administration/common';
import 'components/administration/compliance/compliance-config-profile-section.component';
import 'components/administration/compliance/compliance-config-redflag-section.component';
// administration/compliance
import 'components/administration/compliance/index';
// compliance config
import 'components/administration/compliance/compliance-config.component';
import 'components/administration/compliance/customer-type-transaction-bracket-section.component';
import 'components/administration/compliance/non-commensurate/compliance-non-commensurate-select.component';
import 'components/administration/compliance/non-commensurate/customer-type-config.component';
import 'components/administration/compliance/red-flag-disposition/red-flag-disposition.component';
import 'components/administration/compliance/service/compliance-config.cache';
import 'components/administration/compliance/service/compliance-red-flag-config.cache';
import 'components/administration/custom-field/common/custom-field-category-details.cache';
// administration/product-category
import 'components/administration/custom-field/common/custom-field-definition-multiselect.component';
// administration/deposit-account
import 'components/administration/deposit-account';
import 'components/administration/deposit-account/reservation/deposit-account-reservation.component';
import 'components/administration/depository-account/category-list/depository-account-category-list.component';
import 'components/administration/depository-account/details/depository-account-details.component';
// administration/depository-account
import 'components/administration/depository-account/list/depository-account-list.component';
// administration/rates
import 'components/administration/dynamic-report';
// administration
import 'components/administration/email-notification/email-notification-config.component';
// administration/general-ledgers
import 'components/administration/general-ledger/general-ledger.component';
import 'components/administration/general-ledger/misc-transaction-mapping/misc-transaction-mapping.component';
import 'components/administration/general-ledger/misc-transaction-mapping/misc-transaction-mapping.service';
import 'components/administration/general-ledger/transaction-mapping/gl-transaction-mapping.component';
// administration/gl-mappings
import 'components/administration/gl-mappings/gl-mappings.component';
import 'components/administration/gl-mappings/gl-mappings.service';
import 'components/administration/global-settings';
import 'components/administration/holiday/bank-holidays.component';
import 'components/administration/holiday/holiday-branches.component';
// administration/holiday
import 'components/administration/holiday/holiday-page.component';
import 'components/administration/holiday/holiday-table.component';
import 'components/administration/loan/index.js';
import 'components/administration/official-receipt-booklet';
// administration/organization
import 'components/administration/organization/organization.component';
// administration/prints
import 'components/administration/prints';
import 'components/administration/security';
// administration/sss
import 'components/administration/sss/sss-contributions.component';
import 'components/administration/term-deposit/common/term-deposit-service';
import 'components/administration/term-deposit/details/term-deposit-details.component';
// administration/term-deposit
import 'components/administration/term-deposit/list/term-deposit-list.component';
import 'components/administration/term-deposit/section/advanced/term-deposit-advanced.component';
import 'components/administration/term-deposit/section/term-deposit-fees.component';
import 'components/administration/term-deposit/section/term-deposit-section.component';
import 'components/administration/transactions/transactions-category-details.component';
// administration/transactions
import 'components/administration/transactions/transactions-category-list.component';
// administration/users
import 'components/administration/users/branches/users-branches.component';
import 'components/administration/users/change-branch/users-change-branch.component';
import 'components/administration/users/details/users-details.component';
import 'components/administration/users/list/users-list.component';
import 'components/administration/users/logged-in';
import 'components/administration/users/mfa-configuration/mfa-configuration-page.component';
import 'components/administration/users/mfa-configuration/mfa-configuration.component';
// agent-banking
import 'components/agent-banking';
// atm-terminal
import 'components/atm';
import 'components/batch/deped';
import 'components/batch/loan-payment';
import 'components/batch/loans';
// automatic debit agreement
import 'components/common/ada/ada-auto-configuration-preview.component';
// batch
import 'components/batch/memo/batch-memo.component';
import 'components/batch/memo/history/batch-memo-history.component';
import 'components/batch/sss-pension';
import 'components/batch/remittance/batch-remittance.component';
import 'components/batch/remittance/batch-remittance-upload.component';
import 'components/batch/remittance/batch-remittance-history.service';
import 'components/batch/remittance/batch-remittance-history.component';
import 'components/bss/oracle-handoff-mapping/oracle-handoff-mapping.component';
import 'components/bss/oracle-handoff-mapping/oracle-handoff-mapping.service';
// check preparation
import 'components/check-preparation';
import 'components/common/account-select/account-select.component';
import 'components/common/approval-hierarchy/approval-hierarchy.component';
import 'components/common/branches-list/branches-list.component';
// common components
import 'components/common/breadcrumbs/breadcrumbs.component';
import 'components/common/breadcrumbs/breadcrumbs.service';
import 'components/common/cash/cash.component';
import 'components/common/center-picker/center-picker.component';
import 'components/common/charge/charge.component';
import 'components/common/charge/custom-fee-board.component';
import 'components/common/charge/custom-fee-edit.component';
import 'components/common/charge/custom-fee-list.component';
import 'components/common/charge/custom-fee.component';
import 'components/common/charge/pledge-deposit-deviation-interval-fee-board.component';
import 'components/common/charge/progressive-charge.component';
import 'components/common/check-form/check-form.component';
import 'components/common/customer-picker/customer-picker.component';
import 'components/common/date-range-picker/date-range-picker.component';
import 'components/common/date/date.component';
import 'components/common/day-of-week/day-of-week.component';
import 'components/common/denomination/denomination.component';
import 'components/common/dynamic-list/dynamic-list.component';
import 'components/common/error-page/error-page.component';
import 'components/common/field/field.component';
import 'components/common/file-id-upload/file-id-upload.component';
import 'components/common/file-upload/file-upload-new.component';
import 'components/common/file-upload/file-upload-webcam.component';
import 'components/common/file-upload/file-upload.component';
import 'components/common/file-upload/validate-uploaded.directive';
import 'components/common/filters/branch-filter.component';
import 'components/common/filters/loan-category-filter.component';
import 'components/common/filters/report-filters.component';
import 'components/common/filters/tree-filter.component';
import 'components/common/form-section/form-section-field.component';
import 'components/common/form-section/form-section.component';
import 'components/common/health-check-list';
import 'components/common/inline-panel/inline-panel.component';
import 'components/common/ledger-units/ledger-units.component';
import 'components/common/loan/hierarchy-builder.service';
import 'components/common/loan/loan-industry-purpose.component';
import 'components/common/loan/loan-mis-group.component';
import 'components/common/loan/report-approvals-filters.component';
import 'components/common/local-override/local-override.component';
import 'components/common/micr-number/micr-number.component';
import 'components/common/misc-transaction-units/transaction-units.component';
import 'components/common/missing-access-warning/missing-access-warning.component';
import 'components/common/multiselect/multiselect.component';
import 'components/common/multiselect/null-wrapper/multiselect-null-wrapper.component';
import 'components/common/note-history/note-history.component';
import 'components/common/note-history/note-history.service';
import 'components/common/number-pattern/number-pattern.component';
import 'components/common/pdf-view/pdf-view.component';
import 'components/common/print/modal-print-preview.component';
import 'components/common/product-holds/product-holds.component';
import 'components/common/product-list/product-list.component';
import 'components/common/product-locks/product-locks.component';
import 'components/common/product-owners/product-owners.component';
import 'components/common/qr-code/qr-code.directive';
import 'components/common/query-param-guard/query-param-guard.component';

import 'components/common/refresh-button/refresh-button.component';
import 'components/common/selectable-list/selectable-list.component';
import 'components/common/signature-card/signature-card.component';
import 'components/common/sql-editor/sql-editor.component';
import 'components/common/sub-header/sub-header.component';
import 'components/common/telephone/telephone.component';
import 'components/common/top-bar/top-bar.component';
import 'components/common/transaction-history/transaction-history.component';
import 'components/common/transaction-history/transaction-history.service';
import 'components/common/user-select/user-select.component';
import 'components/common/validator/validator.component';
import 'components/common/webcam/webcam.component';
import 'components/common/wysiwyg-editor/wysiwyg-editor.component';
import 'components/common/zoomable-picture/zoomable-picture.component';
import 'components/customer/accounts/account-group';
import 'components/customer/accounts/checkbook/checkbook-details.component';
//accounts
import 'components/customer/accounts/customer-accounts.component';
import 'components/customer/accounts/details/customer-account-details-print.component';
import 'components/customer/active-customer.service';

// center
import 'components/customer/center';
import 'components/customer/common';

//credit-lines
import 'components/customer/credit-line/index';
import 'components/customer/customer-access';

import 'components/customer/group-loans/index.js';
import 'components/customer/landing-page/customer-landing-page.component';
import 'components/customer/loans/amortization-schedule-report/amortization-schedule-report.component';

import 'components/customer/loans/automatic-transfer-arrangement';
import 'components/customer/loans/collateral/loan-collateral.component'
import 'components/customer/loans/consolidation/loan-consolidation.component'
import 'components/customer/loans/common/loan-automatic-transfer/loan-automatic-transfer.component';
import 'components/customer/loans/common/value-date.component';
import 'components/customer/loans/customer-loans.component';
import 'components/customer/loans/delete-loan/delete-loan-confirmation.component';
import 'components/customer/loans/edit/customer-loans-edit-amortization.component';
import 'components/customer/loans/edit/customer-loans-edit-automatic-transfer.component';
import 'components/customer/loans/edit/customer-loans-edit-co-makers.component';
import 'components/customer/loans/edit/customer-loans-edit-information.component';
import 'components/customer/loans/edit/customer-loans-edit-interest-accrual.component';
import 'components/customer/loans/edit/customer-loans-edit-credit-losses-allowance.component';
import 'components/customer/loans/edit/customer-loans-edit-metadata.component';
import 'components/customer/loans/edit/customer-loans-edit-rates.component';
import 'components/customer/loans/edit/params/customer-loans-edit-params-and-charges.component';
import 'components/customer/loans/loan-accrual-history/loan-accrual-history.component';
import 'components/customer/loans/loan-letter-history/loan-letter-history.component';
import 'components/customer/loans/loan-letters/loan-letters.component';
import 'components/customer/loans/loan-payment-report/loan-payment-report.component';
import 'components/customer/loans/loan-transaction-history/loan-transaction-history.component';
import 'components/customer/loans/recalculate-amortization-schedule/recalculate-amortization-schedule.component';
import 'components/customer/loans/statement-of-account-total/customer-loans-statement-total.component';
import 'components/customer/loans/statement-of-account/customer-loans-statement.component';
import 'components/customer/profile';
import 'components/customer/profile/risk/customer-risk-profile.component';
import 'components/customer/term-deposits/index';
import 'components/dashboard/actions/action-list.component';
// dashboard
import 'components/dashboard/actions/approval-action.component';
import 'components/dashboard/collections/collections.component';
import 'components/dashboard/collections/filter/collections-filter.component';
import 'components/dashboard/collections/payment/collections-payment.component';
import 'components/dashboard/collections/payment/group/collections-payment-group.component';
import 'components/dashboard/collections/payment/individual/collections-payment-individual.component';
import 'components/dashboard/collections/payment/members-modal/collections-payment-members-modal.component';
import 'components/dashboard/history/history-miscellaneous-transactions.component';
import 'components/dashboard/lookup-center/lookup-center.component';
import 'components/dashboard/lookup-customer-group/lookup-customer-group.component';
// customers
import 'components/dashboard/lookup-customer/lookup-customer.component';
import 'components/dashboard/miscellaneous-transactions';
import 'components/dashboard/miscellaneous-transactions/cash-to-from-bank/cash-to-from-bank.component';
import 'components/dashboard/miscellaneous-transactions/cashier-cash-overage/cashier-cash-overage.component';
import 'components/dashboard/miscellaneous-transactions/cashier-cash-shortage/cashier-cash-shortage.component';
import 'components/dashboard/miscellaneous-transactions/check-deposit/check-deposit.component';
import 'components/dashboard/miscellaneous-transactions/check-transfer-to-cashier/check-transfer-to-cashier.component';
import 'components/dashboard/miscellaneous-transactions/close-counters/close-counters.component';
import 'components/dashboard/miscellaneous-transactions/common/action-command';
import 'components/dashboard/miscellaneous-transactions/end-the-day/end-the-day.component';
import 'components/dashboard/miscellaneous-transactions/funds-movement/funds-movement.component';
import 'components/dashboard/miscellaneous-transactions/misc-cash-disbursement/misc-cash-disbursement.component';
import 'components/dashboard/miscellaneous-transactions/miscellaneous-transactions.component';
import 'components/dashboard/miscellaneous-transactions/official-receipt/official-receipt.component';
import 'components/dashboard/miscellaneous-transactions/pos-cash-withdrawal/pos-cash-withdrawal.component';
import 'components/dashboard/miscellaneous-transactions/start-the-day/start-the-day.component';
import 'components/dashboard/miscellaneous-transactions/teller-cash-overage/teller-cash-overage.component';
import 'components/dashboard/miscellaneous-transactions/teller-cash-shortage/teller-cash-shortage.component';
import 'components/dashboard/system-health-check/system-health-check.component';
// general ledger
import 'components/general-ledger/accounts/gl-account-details.component';
import 'components/general-ledger/accounts/gl-accounts.component';
import 'components/general-ledger/common/gl-account-list.component';
import 'components/general-ledger/common/gl-account.service';
import 'components/general-ledger/common/gl-category-input.component';
import 'components/general-ledger/common/gl-category-select.component';
import 'components/general-ledger/common/gl-header.component';
import 'components/general-ledger/common/gl-ledger.service';
import 'components/general-ledger/common/gl-transaction.service';
import 'components/general-ledger/transactions';
// inventory
import 'components/inventory/batch-clearing-page';
import 'components/inventory/batch-pdc-payment';
import 'components/inventory/check';
import 'components/inventory/stop-order';
// menu
import 'components/menu/menu.component';
import 'components/report/custom-query/custom-query.component';
import 'components/report/custom-report/custom-report.component';
import 'components/report/custom-report/custom-report-detail.component';
import 'components/report/modal/report-modal.service';
import 'components/report/report-details.component';
import 'components/report/report-list.component';
//report
import 'components/report/report.service';
import 'components/report/view/report-view.component';
// service
import 'components/service';

import 'components/service/passbook.service';
// import bundles
import 'components/support';
// common technical components
import 'components/technical';
import 'core-js/stable';
import 'daterangepicker';
import $ from 'jquery';
import 'lodash';
// application imports
import nxModule from 'nxModule';
import 'regenerator-runtime/runtime';
// shared
import 'shared';
//profile
import 'shared/customer-profile-commons';

import systemPropertyService from '../react/system/systemPropertyService';

import '../style/bootstrap-legacy-styles/styles.less';
import '../style/styles.scss';
// analytics
import './analytics/analytics.service';

import './components/customer/credit-scoring/credit-scoring-modal.component';
import './libs/angular-table';

import './libs/bootstrap';
import './libs/jquery.gritter.min';
import './nxConfig';

import './shared/utils/jqueryEventListener';

$.gritter.options['parent_element'] = '#modal-container';

if(process.env.NODE_ENV !== 'production') {
  window.nxDevMode = true;
}

// run run services!
nxModule.run((authentication, config, translationCache, formattingService, appVersionService, $location,
            refreshService,
            analyticsService,  // analyticsService is unused, but we still need to include it so it gets initialized
            cmcUpdateService, // keep to initialize the service
            breadcrumbsService, // keep to initialize the service
  ) => {
  //Fetch all translations
  translationCache.toObservable().first().subscribe();
  //Turn off error messaging
  BigNumber.config({ERRORS: false});

  const uiCommaSeparator = systemPropertyService.getProperty('UI_CASH_COMMA_SEPARATOR');
  formattingService.setUiCommaSeparator(uiCommaSeparator === 'TRUE');

  // This is the quasi-main method of the app. It's called after all components have been loaded.
  if (authentication.isSessionExpired()) {
    authentication.logout($location.path());
  }

  appVersionService.isAppOutdated().then(isOutdated => {
    if (isOutdated) {
      refreshService.refreshPage({});
    }
  });

});

