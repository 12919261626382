// Set of functions to process the raw json data from the server, mostly used in Merge Customer for determining differences in previousValues and newValues
export default function processChanges(data: any) {
  const processed = {
    pageNo: data.pageNo,
    result: Array.isArray(data.result)
      ? data.result.map((record: any) => ({
          ...record,
          changes: processChangeRecord(record),
        }))
      : processChangeRecord(data),
    totalCount: data.totalCount,
    resultCount: data.resultCount,
  };
  return Array.isArray(data.result) ? processed : processed.result;
}

/*
    "Flattens" and transforms (ID-based summarization for nested objects) of differences/changes of previousValues to newValues of their values

    INPUT:
    customFieldValues: {
      previousValue: [
        {
          customFieldId: 224,
          customField: "Secondary Control No.",
          customFieldValue: "Test 1",
        },
      ],
      newValue: [
        {
          customFieldId: 224,
          customField: "Secondary Control No.",
          customFieldValue: "Test 123",
        },

      OUTPUT:
      customFieldValues: {
        224: {
          field: "Secondary Control No.",
          previousValue: "Test 1",
          newValue: "Test 123"
        }
*/
function processFieldChanges(changeData: any = {}, fieldType = "customFieldValues") {
  const fieldConfig = {
    customFieldValues: {
      idKey: "customFieldId",
      fieldKey: "customField",
      valueKey: "customFieldValue",
      valuePath: "",
    },
    categoryIds: {
      idKey: "categoryFieldId",
      fieldKey: "categoryField",
      valueKey: "categoryValue",
      valuePath: "",
    },
  };

  const config = (fieldConfig as any)[fieldType];

  const previousValue = changeData.previousValue || {};
  const newValue = changeData.newValue || {};
  const processedFields = {};

  function processValues(values: any, isNewValue: boolean): any {
    if (!Array.isArray(values)) return;

    values.forEach((item) => {
      const id = item[config.idKey];
      const entry = (processedFields as any)[id] || {
        field: item[config.fieldKey],
        previousValue: "-",
        newValue: "-",
      };

      if (isNewValue) {
        entry.newValue = item[config.valueKey];
        entry.field = item[config.fieldKey];
      } else {
        entry.previousValue = item[config.valueKey];
      }

      (processedFields as any)[id] = entry;
    });
  }

  const previousValues = config.valuePath ? previousValue[config.valuePath] : previousValue;
  processValues(previousValues, false);

  const newValues = config.valuePath ? newValue[config.valuePath] : newValue;
  processValues(newValues, true);

  // Filter out entries where previousValue equals newValue
  const changedFields: any = {};
  for (const [id, entry] of Object.entries(processedFields) as any) {
    if (entry?.previousValue !== entry?.newValue) {
      changedFields[id] = entry;
    }
  }

  if (Object.keys(changedFields).length > 0) {
    return { [fieldType]: changedFields };
  }
  return {};
}

// Helper function to organize process fields
function processChangeRecord(record: any) {
  const changes = record.changes;
  const processedChanges = {};

  for (const [key, change] of Object.entries(changes)) {
    if (key === "customFieldValues") {
      const customFieldChanges = processFieldChanges(change, "customFieldValues");
      Object.assign(processedChanges, customFieldChanges);
      continue;
    }

    if (key === "categoryIds") {
      const categoryChanges = processFieldChanges(change, "categoryIds");
      Object.assign(processedChanges, categoryChanges);
      continue;
    }
    if (!change || typeof change !== "object") continue;

    const { previousValue, newValue } = change as any;

    if (previousValue === null && newValue === null) continue;
    if (previousValue === undefined && newValue === undefined) continue;

    // No need for flattening for MERGE_CUSTOMER
    if (record.entryGroup === "MERGE_CUSTOMER") {
      (processedChanges as any)[key] = {
        previousValue: previousValue,
        newValue: newValue,
      };
      continue;
    }

    if (isComplexValue(previousValue) || isComplexValue(newValue)) {
      const nestedChanges = processNestedChanges(key, previousValue, newValue);
      Object.assign(processedChanges, nestedChanges);
      continue;
    }

    if (previousValue !== newValue) {
      (processedChanges as any)[key] = { previousValue, newValue };
    }
  }

  return processedChanges;
}

/* Comparison of nested array of objects such as idDocuments, incomeSources, addresses, etc for their previousValues and newValues especially ID-based comparison
    
    Example:

    previousValue: [{id: 1, name: "John"}, {id: 2, name: "Jane"}]
    newValue: [{id: 1, name: "Mark"}, {id: 3, name: "Doe"}]
    
    result: {
      previousValue: [{id: 1, name: "John"}, {id: 2, name: "Jane"}],
      newValue: [{id: 1, name: "Mark"}, {id: 3, name: "Doe"}]
    }
*/
function processNestedChanges(parentKey: string, previousValue: any, newValue: any) {
  const processedChanges: any = {};

  if (parentKey.endsWith("photoFileIds") || parentKey.endsWith("typeIds")) {
    // Only include in changes if arrays are different
    if (compareArrayObjectFields(previousValue, newValue)) {
      processedChanges[parentKey] = {
        previousValue,
        newValue,
      };
    }
    return processedChanges;
  }

  if (!isComplexValue(previousValue) && !isComplexValue(newValue)) {
    if (previousValue !== newValue) {
      processedChanges[parentKey] = {
        previousValue,
        newValue,
      };
    }
    return processedChanges;
  }

  // Handle arrays with ID-based comparison
  /*
    previousValue: [{id: 1, name: "John"}, {id: 2, name: "Jane"}]
    newValue: [{id: 1, name: "Mark"}, {id: 3, name: "Doe"}]
    
    result: {
      previousValue: [{id: 1, name: "John"}, {id: 2, name: "Jane"}],
      newValue: [{id: 1, name: "Mark"}, {id: 3, name: "Doe"}]
    }
 */
  if (Array.isArray(previousValue) || Array.isArray(newValue)) {
    const prevArray = Array.isArray(previousValue) ? previousValue : [];
    const newArray = Array.isArray(newValue) ? newValue : [];

    const prevMap = new Map(prevArray.filter((item) => item?.id).map((item) => [item.id, item]));
    const newMap = new Map(newArray.filter((item) => item?.id).map((item) => [item.id, item]));

    const allIds = new Set([...prevMap.keys(), ...newMap.keys()]);
    const allIdsArray = Array.from(allIds);

    for (let i = 0; i < allIdsArray.length; i++) {
      const id = allIdsArray[i];
      const prevItem = prevMap.get(id);
      const newItem = newMap.get(id);

      if (prevItem || newItem) {
        const allFields = new Set([...Object.keys(prevItem || {}), ...Object.keys(newItem || {})]);

        for (const field of allFields) {
          if (field === "id") continue;

          const prevValue = prevItem?.[field];
          const newValue = newItem?.[field];

          const fieldKey = `${parentKey} ${i + 1} - ${field}`;

          // Handle nested objects recursively
          if (isComplexValue(prevValue) || isComplexValue(newValue)) {
            const nestedChanges = processNestedChanges(fieldKey, prevValue, newValue);
            Object.assign(processedChanges, nestedChanges);
          } else if (field === "photoFileIds" || field === "typeIds") {
            if (compareArrayObjectFields(prevValue, newValue)) {
              processedChanges[fieldKey] = {
                ...(prevValue ? { previousValue: prevValue } : {}),
                ...(newValue ? { newValue: newValue } : {}),
              };
            }
          } else if (prevValue !== newValue) {
            processedChanges[fieldKey] = {
              // eslint-disable-next-line
              ...(prevItem?.hasOwnProperty(field) ? { previousValue: prevValue } : {}),
              // eslint-disable-next-line
              ...(newItem?.hasOwnProperty(field) ? { newValue: newValue } : {}),
            };
          }
        }
      }
    }

    return processedChanges;
  }

  // Handle regular objects
  const prevObj = previousValue || {};
  const newObj = newValue || {};
  const allKeys = new Set([...Object.keys(prevObj), ...Object.keys(newObj)]);

  for (const key of allKeys) {
    const prevVal = prevObj[key];
    const newVal = newObj[key];

    if (prevVal === newVal) continue;

    const fieldName = parentKey ? `${parentKey} - ${key}` : key;

    if (isComplexValue(prevVal) || isComplexValue(newVal)) {
      const nestedChanges = processNestedChanges(fieldName, prevVal, newVal);
      Object.assign(processedChanges, nestedChanges);
    } else {
      processedChanges[fieldName] = {
        // eslint-disable-next-line
        ...(prevObj.hasOwnProperty(key) ? { previousValue: prevVal } : {}),
        // eslint-disable-next-line
        ...(newObj.hasOwnProperty(key) ? { newValue: newVal } : {}),
      };
    }
  }

  return processedChanges;
}

function isComplexValue(value: any) {
  return value !== null && typeof value === "object" && !(value instanceof Date);
}

/*
  For comparison of attributes such as photoFieldsIds and typeIds that are arrays
   idDocuments: {
      previousValue: [
        {
          photoFileIds: ["document_photo_1.png"],
        }
      ],
      newValue: [
        {
          photoFileIds: ["document_photo_2.png"],
        },
      ],
    },
*/
function compareArrayObjectFields(previousFiles: any[], newFiles: any[]): boolean {
  if (!previousFiles) previousFiles = [];
  if (!newFiles) newFiles = [];

  if (previousFiles.length === 0 && newFiles.length > 0) {
    return true;
  }

  if (newFiles.length === 0 && previousFiles.length > 0) {
    return true;
  }

  if (previousFiles.length === 0 && newFiles.length === 0) {
    return false;
  }

  if (previousFiles.length !== newFiles.length) {
    return true;
  }

  for (let i = 0; i < previousFiles.length; i++) {
    if (previousFiles[i] !== newFiles[i]) {
      return true;
    }
  }

  return false;
}
