import React, { useState, useEffect } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";
import styles from "./TableStyle.scss";
import { CreateCustomerView } from "./table-row-views/CreateCustomerView";
import { MergeCustomerView } from "./table-row-views/MergeCustomerView";
import { UpdateCustomerView } from "./table-row-views/UpdateCustomerView";
import { CloseReactivateCustomerView } from "./table-row-views/CloseReactivateCustomerView";

export interface TableColumn<T> {
  header: string;
  accessorKey?: keyof T;
  cell?: (props: { row: any }) => React.ReactNode;
}

export interface ExpandableRowsTableProps<T> {
  data: T[];
  columns: TableColumn<T>[];
  pageSize?: number;
  currentPage?: number;
  totalPages?: number;
  totalResults?: number;
  onPageChange?: (page: number) => void;
}

const ExpandedContent = ({ data }: { data: any }) => {
  const renderContent = () => {
    switch (data.entryGroup) {
      case "MERGE_CUSTOMER":
        return <MergeCustomerView changes={data.changes} />;
      case "CREATE_CUSTOMER":
        return <CreateCustomerView changes={data.changes} />;
      case "UPDATE_CUSTOMER":
        return <UpdateCustomerView changes={data.changes} />;
      case "REACTIVATE_CUSTOMER":
        return <CloseReactivateCustomerView changes={data.changes} />;
      case "CLOSE_CUSTOMER":
        return <CloseReactivateCustomerView changes={data.changes} />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.expandedContent}>
      <div>{renderContent()}</div>

      <div className={styles.detailItem}>
        <p>Command ID: {data.commandId}</p>
        <p>Transacting branch: {data.branchName}</p>
      </div>
    </div>
  );
};

export function ExpandableRowsTable<T>({
  data,
  columns,
  pageSize = 10,
  currentPage = 0,
  totalPages = 1,
  totalResults = 0,
  onPageChange,
}: ExpandableRowsTableProps<T>) {
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});
  const [pagination, setPagination] = useState({
    pageIndex: currentPage,
    pageSize,
  });

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: currentPage,
    }));
  }, [currentPage]);

  const handlePageChange = (newPage: number) => {
    if (onPageChange) {
      onPageChange(newPage);
    }
  };

  const tableColumns = [
    ...columns,
    {
      id: "expander",
      header: () => null,
      cell: function renderCell({ row }: { row: any }) {
        return (
          <button
            className={styles.expandButton}
            onClick={() => {
              setExpanded((old: any) => ({
                ...old,
                [row.id]: !old[row.id],
              }));
            }}
          >
            <span
              className={`${styles.chevron} ${expanded[row.id] ? styles["chevronDown"] : styles["chevronRight"]}`}
            />
          </button>
        );
      },
    },
  ];

  const table = useReactTable({
    data,
    columns: tableColumns,
    state: {
      expanded,
      pagination,
    },
    pageCount: totalPages,
    onExpandedChange: setExpanded,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
  });

  const startIndex = currentPage * pageSize + 1;
  const endIndex = Math.min((currentPage + 1) * pageSize, totalResults);

  const generatePaginationNumbers = () => {
    const totalPages = table.getPageCount();

    if (totalPages <= 7) {
      return Array.from({ length: totalPages }, (_, i) => i);
    }

    if (currentPage < 4) {
      return [0, 1, 2, 3, 4, 5, 6, "...", totalPages - 1];
    }

    if (currentPage >= totalPages - 4) {
      return [
        0,
        "...",
        totalPages - 7,
        totalPages - 6,
        totalPages - 5,
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
      ];
    }

    return [0, "...", currentPage - 1, currentPage, currentPage + 1, "...", totalPages - 1];
  };

  const paginationNumbers = generatePaginationNumbers();
  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <React.Fragment key={row.id}>
              <tr>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
              {expanded[row.id] && (
                <tr>
                  <td colSpan={columns.length + 1}>
                    <ExpandedContent data={row.original} />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <div className={styles.pagination}>
        {totalPages > 1 && (
          <div className={styles.paginationControls}>
            <div className={styles.paginationButtons}>
              <button
                className={styles.paginationButton}
                onClick={() => handlePageChange(0)}
                disabled={currentPage === 0}
                title="First Page"
              >
                {"<<"}
              </button>

              {paginationNumbers.map((pageNum, idx) =>
                pageNum === "..." ? (
                  <span key={`ellipsis-${idx}`} className={styles.paginationEllipsis}>
                    ...
                  </span>
                ) : (
                  <button
                    key={`page-${pageNum}`}
                    className={`${styles.paginationButton} ${
                      currentPage === pageNum ? styles.paginationButtonActive : ""
                    }`}
                    onClick={() => handlePageChange(Number(pageNum))}
                  >
                    {Number(pageNum) + 1}
                  </button>
                )
              )}

              <button
                className={styles.paginationButton}
                onClick={() => handlePageChange(totalPages - 1)}
                disabled={currentPage >= totalPages - 1}
                title="Last Page"
              >
                {">>"}
              </button>
            </div>
          </div>
        )}
        <div className={styles.paginationInfo}>
          {startIndex} - {endIndex} of {totalResults}
        </div>
      </div>
    </div>
  );
}
