import {Columns} from 'components/common/dynamic-list/dynamic-list.component';
import {AttachedFile} from 'components/service/file.service';
import nxModule from 'nxModule';
import ConfirmationTemplate from 'shared/common/confirmationTemplate';
import {CommandService} from 'shared/utils/command/command.types';
import templateUrl from './outgoing-instapay-transfer-manual-recon.template.html';

interface OutgoingInstapayTransferManualReconOutput {
  successfulRejections: TransferDetails[];
  failedRejections: TransferDetails[];
}

interface TransferDetails {
  referenceNumber: string;
  providerReferenceNumber: string;
  rejectionStatusCode: string;
  rejectionRemarks: string;
}

class OutgoingInstapayTransferManualRecon {
  private contentFile : AttachedFile[] | null = [];
  private result: OutgoingInstapayTransferManualReconOutput | null = null;

  private readonly columns: Columns = [
    { title: 'Reference Number', field: 'referenceNumber'},
    { title: 'Provider Reference Number', field: 'providerReferenceNumber'},
    { title: 'Rejection Status Code', field: 'rejectionStatusCode'},
    { title: 'Proprietary Status Code', field: 'proprietaryStatusCode'},
    { title: 'Remarks', field: 'rejectionRemarks'}
  ];

  constructor(private confirmationTemplate: ConfirmationTemplate,
              private command: CommandService
  ) {
  }

  private downloadSampleFile(): void {
    const blobParts: BlobPart[] = [`reference_number,provider_reference_number,rejection_status_code,proprietary_status_code
202501061,,AC01,
,20250106CUOXXXX2XXXB000000000227294,,9909`];

    const sampleFileUrl = window.URL.createObjectURL(new Blob(blobParts, {type: 'text/csv'}));
    const a = window.document.createElement('a');
    a.href = sampleFileUrl;
    a.download = 'outgoing-instapay-transfer-manual-recon.csv';
    a.click();
  }

  private async execute(): Promise<void> {
    const confirmed = await this.confirmationTemplate({
      question: 'Are you sure you want to proceed?',
    });
    if (!confirmed || this.contentFile == null) {
      return;
    }

    const { output } = await this.command.execute<unknown, OutgoingInstapayTransferManualReconOutput>('OutgoingInstapayTransfersManualReconciliation', {
      fileId: this.contentFile[0].id
    }).toPromise();

    this.result = {
      ...output
    };
  }

  async clear(): Promise<void> {
    this.result = null;
  }
}

nxModule.component("outgoingInstapayTransferManualRecon", {
  controller: OutgoingInstapayTransferManualRecon,
  templateUrl
})