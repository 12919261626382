export const MergeCustomerView = ({ changes }: { changes: Record<string, any> }) => {
  const isClosedAtMerge = "closedAt" in changes;

  if (isClosedAtMerge) {
    const mergedData = changes.mergedInto?.newValue || {};

    return (
      <table>
        <thead>
          <tr>
            <th>Merged to CIF Number</th>
            <th>Name</th>
            <th>Mother Branch</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{mergedData.customerNumber || <strong>None</strong>}</td>
            <td>{mergedData.effectiveName || <strong>None</strong>}</td>
            <td>{mergedData.branchName || <strong>None</strong>}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  const previousRows = Array.isArray(changes.mergedCustomers?.previousValue)
    ? changes.mergedCustomers.previousValue
    : [];

  const newValue = Array.isArray(changes.mergedCustomers?.newValue) ? changes.mergedCustomers.newValue : [];
  const lastNewRow = newValue[newValue.length - 1] || {};

  const allRows = [...previousRows, lastNewRow].filter((row) => Object.keys(row).length > 0);

  return (
    <table>
      <thead>
        <tr>
          <th>Merged CIF Number</th>
          <th>Name</th>
          <th>Mother Branch</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {allRows.map((row, index) => (
          <tr key={index}>
            <td>{row.customerNumber || <strong>None</strong>}</td>
            <td>{row.effectiveName || <strong>None</strong>}</td>
            <td>{row.branchName || <strong>None</strong>}</td>
            <td>{row.status   || <strong>None</strong>}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
