export const translations = {
  // System/General
  id: "ID",
  type: "Type",
  status: "Status",
  name: "Name",
  number: "Number",
  date: "Date",
  time: "Time",
  remarks: "Remarks",
  primary: "Primary",
  description: "Description",
  amount: "Amount",

  // Entry Groups
  createCustomer: "CIF Created",
  mergeCustomer: "Merged customers",
  updateCustomer: "CIF Updated",
  notApplicable: "N/A",
  reactivateCustomer: "CIF Reactivated",
  closeCustomer: "CIF Closed",

  // Individual Data
  individualData: "Individual Data",
  title: "Title",
  firstName: "First Name",
  middleName: "Middle Name",
  lastName: "Last Name",
  extension: "Name Extension",
  nickname: "Nickname",
  previousLastName: "Previous Last Name",
  gender: "Gender",
  birthDate: "Birth Date",
  birthPlace: "Birth Place",
  birthCountry: "Birth Country",
  citizenship: "Citizenship",
  residence: "Country of Residence",
  civilStatus: "Civil Status",
  height: "Height",
  weight: "Weight",
  age: "Age",

  // Contact Data
  contactData: "Contact Data",
  email: "Email Address",
  landlineNumber: "Landline Number",
  mobileNumber: "Mobile Number",
  workNumber: "Work Number",
  phoneType: "Phone Type",
  phonePlan: "Phone Plan",
  monthlyExpense: "Monthly Expense",

  // Documents
  documents: "Documents",
  documentType: "Document Type",
  documentStatus: "Document Status",
  documentNumber: "Document Number",
  documentName: "Document Name",
  issuingAuthority: "Issuing Authority",
  issuingCountry: "Issuing Country",
  issuanceDate: "Issuance Date",
  submissionDate: "Submission Date",
  expirationDate: "Expiration Date",
  documentPhoto: "Document Photo",

  // Address
  address: "Address",
  addressType: "Address Type",
  yearsOfStay: "Years of Stay",
  ownership: "Ownership",
  country: "Country",
  province: "Province",
  city: "City",
  barangay: "Barangay",
  street: "Street",
  postalCode: "Postal Code",
  rentAmount: "Rent Amount",
  rentContract: "Rent Contract",

  // Income
  income: "Income",
  incomeType: "Income Type",
  monthlyIncome: "Monthly Income",
  annualIncome: "Annual Income",
  natureOfWork: "Nature of Work/Business",
  psicIndustry: "PSIC/Industry",
  workplace: "Workplace",

  // Employer
  employer: "Employer",
  employerName: "Employer Name",
  employerBranch: "Employer Branch",
  employerType: "Employer Type",
  employmentStartDate: "Employment Start Date",

  // Business
  business: "Business",
  businessName: "Business Name",
  tradeName: "Trade Name",
  businessType: "Business Type",
  businessTin: "Business TIN",
  employees: "No. of Employees",
  businessStartDate: "Business Start Date",
  businessStructure: "Business Structure",
  totalAssets: "Total Assets",
  branches: "Number of Branches",
  dailySales: "Daily Sales",
  monthlySales: "Monthly Sales",
  monthlyExpenses: "Monthly Expenses",

  // Corporate
  corporateData: "Corporate Data",
  sourceOfFunds: "Source of Funds",
  registrationDate: "Registration Date",
  registrationNumber: "Registration Number",
  registrationCountry: "Registration Country",
  placeOfIncorporation: "Place of Incorporation",
  riskLevel: "Risk Level",

  // Custom Fields
  customField: "Custom Field",
  customFieldValue: "Field Value",

  // Categories
  category: "Category",
  categoryField: "Category Field",
  categoryValue: "Category Value",

  // Related People
  relationship: "Relationship",
  beneficiary: "Beneficiary",
  dependent: "Dependent",

  // Merge Info
  customerNumber: "CIF Number",
  effectiveName: "Name",
  branchName: "Mother Branch",
  mergedTo: "Merged to",

  // System Fields
  branch: "Branch",
  command: "Command",
  userId: "User ID",
  updatedAt: "Last Updated",
  createdAt: "Created Date",
  updatedBy: "Last Updated By",
  createdBy: "Created By",
  systemDateCreated: "System Date Created",

  // Compliance
  dosri: "DOSRI",
  pwd: "PWD",
  creditLine: "Credit Line",
  cocree: "Co-Cree",
  membership: "Membership",

  // DepEd Fields
  depEdRegion: "DepEd Region",
  depEdDivision: "DepEd Division",
  depEdStation: "DepEd Station",
  depEdEmployee: "DepEd Employee",

  // Status Values
  executed: "Processed",
  rejected: "Rejected",
  pending: "Pending",
  active: "Active",
  closed: "Closed",

  // Table Headers
  action: "Action",
  executionDateTime: "Execution Date & Time",
  initiatingUser: "Initiating User",
  approver: "Approver",
  approvalDateTime: "Approval Date & Time",
  commandStatus: "Status",
};

export function translateFieldKey(key: string): string {
  const exactTranslation = (translations as any)[key];
  if (exactTranslation) return exactTranslation;

  const kebabKey = key.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();

  const parts = kebabKey.split(/[-_\s]+/);

  const joinedKey = parts.join("");
  if ((translations as any)[joinedKey]) return (translations as any)[joinedKey];

  const translatedParts = parts.map((part) => {
    if (/^\d+$/.test(part)) return part;

    const translation = (translations as any)[part];
    if (translation) return translation;

    return part.charAt(0).toUpperCase() + part.slice(1);
  });

  return translatedParts.join(" ");
}

export function stripBrackets(field: string): string {
  return field.replace(/[[\]"]/g, '');
}

export function formatDate(input: string): string {
  if (/^\d+$/.test(input)) {
    return input;
  }
  const date = new Date(input);

  if (isNaN(date.getTime())) {
    return input;
  }

  const month = date.toLocaleString('en-US', { month: 'numeric' });
  const day = date.toLocaleString('en-US', { day: 'numeric' });
  const year = date.toLocaleString('en-US', { year: 'numeric' });
  const time = date.toLocaleString('en-US', { 
    hour: '2-digit', 
    minute: '2-digit', 
    second: '2-digit', 
    hour12: true 
  });

  return `${month}/${day}/${year}, ${time}`;
}