import styles from "../TableStyle.scss";
import { translateFieldKey, stripBrackets, formatDate } from "profile-history-table/translations";

interface Change {
  field?: any;
  previousValue: any;
  newValue: any;
}

interface NestedChanges {
  [key: string]: Change | NestedChanges;
}

interface ChangeDetail {
  id?: string;
  field: string;
  previousValue?: any;
  newValue?: any;
  type?: "category" | "custom";
}

const formatFieldName = (field: string): string => {
  if (field.includes("—")) {
    return field;
  }

  field = field.replace(/companyTotalAssets/gi, "businessTotalAssets");

  const parts = field.split(/\s+[-]\s+/);
  if (parts.length > 1) {
    return parts.join(" — ");
  }

  const words = field.split(/(?=[A-Z])|[\s-]+/).filter(Boolean);

  const sections: string[] = [];
  let currentSection: string[] = [];

  words.forEach((word, index) => {
    const isNewSection = [
      "Corporate",
      "Individual",
      "Contact",
      "Personal",
      "Demographics",
      "Income",
      "Address",
      "Document",
    ].includes(word);

    if (isNewSection && currentSection.length > 0) {
      sections.push(currentSection.join(" "));
      currentSection = [];
    }

    currentSection.push(word);

    if (index === words.length - 1 && currentSection.length > 0) {
      sections.push(currentSection.join(" "));
    }
  });

  return sections.join(" — ");
};

export const UpdateCustomerView: React.FC<{ changes: NestedChanges }> = ({ changes }: any) => {
  const formatValue = (value: any, field?: string, isNewValue?: boolean): React.ReactNode => {
    if (value === undefined && field) {
      const formattedField = formatFieldName(field).split(/\d+/)[0].trim();
      return isNewValue ? <strong>{formattedField} Removed</strong> : <strong>Newly added {formattedField}</strong>;
    }

    if (value === null || value === undefined || value === "") {
      return <strong>None</strong>;
    }
    if (Array.isArray(value)) {
      return value.length === 0 ? <strong>None</strong> : stripBrackets(JSON.stringify(value));
    }
    if (typeof value === "object") return JSON.stringify(value);
    if (typeof value === "string") return value;

    //Format income to human readable value appended with currency
    const cashFields = ["annual income", "amount", "sales", "expenses", "assets"];
    if (typeof value === "number" && field && cashFields.some(keyword => field.toLowerCase().includes(keyword))) {
      return new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(value) + " PHP";
    }
    return formatDate(String(value));
  };

  const flattenChanges = (
    changes: NestedChanges,
    parentKey = "",
    results: Array<{
      id?: string;
      field: string;
      previousValue?: any;
      newValue?: any;
      type?: "category" | "custom";
    }> = []
  ): any => {
    Object.entries(changes).forEach(([key, value]) => {
      const currentKey = parentKey ? `${parentKey} - ${key}` : key;
      const formattedKey = formatFieldName(currentKey);

      if (value && typeof value === "object") {
        if (key === "categoryIds") {
          Object.entries(value).forEach(([id, fieldData]) => {
            results.push({
              id,
              field: `Category — ${fieldData.field}`,
              previousValue: fieldData.previousValue,
              newValue: fieldData.newValue,
              type: "category",
            });
          });
        } else if (key === "customFieldValues") {
          Object.entries(value).forEach(([id, fieldData]) => {
            results.push({
              id,
              field: `Custom field — ${fieldData.field}`,
              previousValue: fieldData.previousValue,
              newValue: fieldData.newValue,
              type: "custom",
            });
          });
        } else if ("previousValue" in value || "newValue" in value) {
          results.push({
            field: formatFieldName(translateFieldKey(formattedKey)),
            ...(Object.prototype.hasOwnProperty.call(value, "previousValue")
              ? { previousValue: value.previousValue }
              : {}),
            ...(Object.prototype.hasOwnProperty.call(value, "newValue") ? { newValue: value.newValue } : {}),
          });
        } else {
          flattenChanges(value as NestedChanges, formattedKey, results);
        }
      }
    });

    return results;
  };

  const flattenedChanges = flattenChanges(changes);

  const categoryChanges = flattenedChanges.filter((change: any) => change.type === "category");
  const customFieldChanges = flattenedChanges.filter((change: any) => change.type === "custom");
  const regularChanges = flattenedChanges.filter((change: any) => !change.type);

  return (
    <div>
      {/* Custom Fields Table */}
      {customFieldChanges.length > 0 && (
        <table className={styles.updateTable}>
          <thead>
            <tr>
              <th>Custom field id</th>
              <th>Field</th>
              <th>Previous Value</th>
              <th>Updated Value</th>
            </tr>
          </thead>
          <tbody>
            {customFieldChanges.map((change: ChangeDetail, index: number) => (
              <tr key={`custom-${change.id}-${index}`}>
                <td>{change.id}</td>
                <td>{change.field}</td>
                <td>{formatValue(change.previousValue)}</td>
                <td>{formatValue(change.newValue)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Category Fields Table */}
      {categoryChanges.length > 0 && (
        <table className={styles.updateTable}>
          <thead>
            <tr>
              <th>Category id</th>
              <th>Field</th>
              <th>Previous Value</th>
              <th>Updated Value</th>
            </tr>
          </thead>
          <tbody>
            {categoryChanges.map((change: ChangeDetail, index: number) => (
              <tr key={`category-${change.id}-${index}`}>
                <td>{change.id}</td>
                <td>{change.field}</td>
                <td>{formatValue(change.previousValue)}</td>
                <td>{formatValue(change.newValue)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Regular Fields Table */}
      {regularChanges.length > 0 && (
        <table className={styles.updateTable}>
          <thead>
            <tr>
              <th>Field</th>
              <th>Previous Value</th>
              <th>Updated Value</th>
            </tr>
          </thead>
          <tbody>
            {regularChanges.map((change: ChangeDetail, index: number) => (
              <tr key={`regular-${change.field}-${index}`}>
                <td>{change.field}</td>
                <td>{formatValue(change.previousValue, change.field, false)}</td>
                <td>{formatValue(change.newValue, change.field, true)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
